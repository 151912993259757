import { useCallback, useEffect, useRef } from "react";
import {
  GoogleMap,
  InfoWindow,
  useJsApiLoader,
  OverlayView,
} from "@react-google-maps/api";
import { useState } from "react";
import firebaseService from "../firebase_service/firebaseService";
import { isVideo, resizeCloudinary, onlineCheck } from "../utils/util";
import { Device, DeviceReport } from "Types";
import { calcClockTime } from "../components/Widgets/CurrTime";
import { NavLink } from "react-router-dom";
import { Spinner } from "react-bootstrap";

let googleMap: google.maps.Map;
type Cluster = {
  location: { latitude: number; longitude: number };
  devices: Array<Device>;
};
function avgLoc(devices: Array<Device>): {
  latitude: number;
  longitude: number;
} {
  let latidue = 0;
  let longitude = 0;
  for (let d of devices) {
    latidue += d.randomLocation!.latitude;
    longitude += d.randomLocation!.longitude;
  }
  return {
    latitude: latidue / devices.length,
    longitude: longitude / devices.length,
  };
}
function getRandomColor() {
  function rainbow(numOfSteps: number, step: number): string {
    // This function generates vibrant, "evenly spaced" colours (i.e. no clustering). This is ideal for creating easily distinguishable vibrant markers in Google Maps and other apps.
    // Adam Cole, 2011-Sept-14
    // HSV to RBG adapted from: http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript
    let r = 0;
    let g = 0;
    let b = 0;
    let h = step / numOfSteps;
    let i = ~~(h * 6);
    let f = h * 6 - i;
    let q = 1 - f;
    switch (i % 6) {
      case 0:
        r = 1;
        g = f;
        b = 0;
        break;
      case 1:
        r = q;
        g = 1;
        b = 0;
        break;
      case 2:
        r = 0;
        g = 1;
        b = f;
        break;
      case 3:
        r = 0;
        g = q;
        b = 1;
        break;
      case 4:
        r = f;
        g = 0;
        b = 1;
        break;
      case 5:
        r = 1;
        g = 0;
        b = q;
        break;
    }
    let c =
      "#" +
      ("00" + (~~(r * 255)).toString(16)).slice(-2) +
      ("00" + (~~(g * 255)).toString(16)).slice(-2) +
      ("00" + (~~(b * 255)).toString(16)).slice(-2);
    return c;
  }
  function random(range: number) {
    return Math.floor(Math.random() * range);
  }
  return rainbow(14, random(14));
}
function getRandomLocation(
  latitude: number,
  longitude: number,
  radiusInMeters: number,
) {
  function getRandomCoordinates(radius: number, uniform: boolean) {
    // Generate two random numbers
    var a = Math.random(),
      b = Math.random();
    // Flip for more uniformity.
    if (uniform) {
      if (b < a) {
        var c = b;
        b = a;
        a = c;
      }
    }
    // It's all triangles.
    return [
      b * radius * Math.cos((2 * Math.PI * a) / b),
      b * radius * Math.sin((2 * Math.PI * a) / b),
    ];
  }


  const randomCoordinates = getRandomCoordinates(radiusInMeters, true);

  // Earths radius in meters via WGS 84 model.
  const earth = 6378137;

  // Offsets in meters.
  const northOffset = randomCoordinates[0];
  const eastOffset = randomCoordinates[1];

  // Offset coordinates in radians.
  const offsetLatitude = northOffset / earth;
  const offsetLongitude =
    eastOffset / (earth * Math.cos(Math.PI * (latitude / 180)));

  // Offset position in decimal degrees.
  return {
    latitude: latitude + offsetLatitude * (180 / Math.PI),
    longitude: longitude + offsetLongitude * (180 / Math.PI),
  };
}
function KlydoMap() {
  const [devices, setDevices] = useState<Array<Device>>();
  const [loadingPage, setLoadingPage] = useState(true);
  const [center, setCenter] = useState(
    localStorage.getItem("mapCenter")
      ? JSON.parse(localStorage.getItem("mapCenter")!)
      : {
          lat: 31.771959,
          lng: 35.217018,
        },
  );
  const [zoom, setZoom] = useState(
    parseInt(localStorage.getItem("mapZoom")!) || 6,
  );
  const [selectedDevice, setSelectedDevice] = useState<Device>();
  const [size, setSize] = useState<{ width: number; height: number }>();
  const [bounds, setBounds] = useState(googleMap?.getBounds());
  const [devicesReport, setDevicesReport] = useState<Array<DeviceReport>>();

  useEffect(() => {
    const r = (devicesReport: Array<DeviceReport>) => {
      setDevicesReport(
        devicesReport.map((d) => {
          if (d.location && !d.randomLocation)
            d.randomLocation = getRandomLocation(
              d.location.latitude,
              d.location.longitude,
              2000,
            );
          return d;
        }),
      );
    };
    firebaseService.listen("machinesReport", r);
    return () => {
      firebaseService.removeListener("machinesReport", r);
    };
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    const l = (devices: Array<Device>) => {
      setDevices(
        devices.map((d) => {
          if (d.location && !d.randomLocation)
            d.randomLocation = getRandomLocation(
              d.location.value.latitude,
              d.location.value.longitude,
              2000,
            );
          return d;
        }),
      );
      setLoadingPage(false);
    };
    firebaseService.listen("machines", l);
    return () => {
      window.removeEventListener("resize", handleResize);
      firebaseService.removeListener("machines", l);
    };
  }, []);

  const ref = useRef<HTMLDivElement | null>(null);

  const getReportData = useCallback(
    (id: string) => {
      const machineReport = devicesReport?.find((d) => d.id === id);
      return machineReport ? machineReport : selectedDevice;
    },
    [selectedDevice, devicesReport],
  );

  const [selectedDeviceReports, setSelectedDeviceReports] = useState(() =>
    getReportData(selectedDevice?.idf || ""),
  );
  useEffect(() => {
    setSelectedDeviceReports(getReportData(selectedDevice?.idf || ""));
  }, [selectedDevice, getReportData]);

  function handleResize() {
    if (ref.current != null) {
      const xy = ref.current.getBoundingClientRect();
      let width = window.innerWidth - xy.left;
      const height = window.innerHeight - xy.top - 1;
      setSize({ width: width, height: height });
    }
  }

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyC5XG3LtScVeWzLYbiYE-JXY4GLFRnGduQ",
  });
  const handleZoomOnLocation = (
    location: { lat: number; lng: number },
    zoom: number,
  ) => {
    setCenter(location);
    setZoom(zoom);
  };

  function handleMarkerClick(cur: Device) {
    setSelectedDevice(cur);
  }

  function handleCloseInfoWindow(): void {
    setSelectedDevice(undefined);
  }
  return (
    <div ref={ref}>
      <NavLink
        to={"/admin/devices"}
        style={{
          marginTop: "10px",
          left: "178px",
          zIndex: "1",
          background: "none padding-box rgb(255, 255, 255)",
          display: "table-cell",
          border: "0px",
          paddingTop: "7px",
          textTransform: "none",
          appearance: "none",
          position: "absolute",
          cursor: "pointer",
          userSelect: "none",
          overflow: "hidden",
          textAlign: "center",
          height: "40px",
          verticalAlign: "middle",
          color: "rgb(86, 86, 86)",
          fontFamily: "Roboto, Arial, sans-serif",
          fontSize: "18px",
          boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px",
          minWidth: "66px",
        }}
        onClick={() => {
          if (!bounds) return;
          const p1 = bounds.getNorthEast();
          const p2 = bounds.getSouthWest();
          localStorage.setItem(
            "devicessearch",
            `range=${p2.lat()},${p1.lat()},${p2.lng()},${p1.lng()}//config`,
          );
        }}
      >
        filter
      </NavLink>
      {isLoaded && !loadingPage ? (
        <div style={{ width: size?.width + "px", height: size?.height + "px" }}>
          <GoogleMap
            onBoundsChanged={() => setBounds(googleMap?.getBounds())}
            onCenterChanged={() => {
              if (!googleMap) return;
              const cnt = googleMap.getCenter()!;
              localStorage.setItem(
                "mapCenter",
                JSON.stringify({ lat: cnt.lat(), lng: cnt.lng() }),
              );
            }}
            onZoomChanged={() => {
              if (!googleMap) return;
              localStorage.setItem("mapZoom", googleMap.getZoom() + "");
            }}
            onLoad={(map) => {
              googleMap = map;
            }}
            mapContainerStyle={{ width: "100%", height: "100%" }}
            center={center}
            zoom={zoom}
            options={{ maxZoom: 100 }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
              }}
            ></div>
            {bounds &&
              size &&
              (() => {
                const p1 = bounds.getNorthEast();
                const p2 = bounds.getSouthWest();
                const latPixels =
                  (Math.abs(p1.lat() - p2.lat()) / size.width) * 24;
                const lngPixels =
                  (Math.abs(p1.lng() - p2.lng()) / size.height) * 24;
                let arr = devices?.filter((d) => {
                  if (!d.randomLocation || !d.klydo) return false;
                  if (p1.lng() < p2.lng()) {
                    if (d.randomLocation.longitude >= 0) {
                      return (
                        d.randomLocation.latitude < p1.lat() &&
                        d.randomLocation.latitude > p2.lat() &&
                        d.randomLocation.longitude > p2.lng()
                      )
                    }
                    else {
                      return (
                        d.randomLocation.latitude < p1.lat() &&
                        d.randomLocation.latitude > p2.lat() &&
                        d.randomLocation.longitude < p1.lng() 
                      )
                    }
                                  
                  }
                  else {
                    return (              
                      d.randomLocation.latitude < p1.lat() &&
                      d.randomLocation.latitude > p2.lat() &&
                      d.randomLocation.longitude < p1.lng() &&
                      d.randomLocation.longitude > p2.lng()
                    );
                  }      
                  
                });
                const newArr = Array<Device | Cluster>();
                while (arr!.length > 0) {
                  const d = arr!.pop()!;
                  const a = arr!.filter(
                    (i) =>
                      Math.abs(
                        i.randomLocation!.latitude - d.randomLocation!.latitude,
                      ) < latPixels &&
                      Math.abs(
                        i.randomLocation!.longitude -
                          d.randomLocation!.longitude,
                      ) < lngPixels,
                  );
                  if (a.length === 0) {
                    newArr.push(d);
                  } else {
                    arr = arr!.filter((i) => !a.includes(i));
                    a.push(d);
                    newArr.push({ devices: a, location: avgLoc(a) });
                  }
                }

                return newArr;
              })().map((c, i) => {
                if ((c as Cluster).devices) {
                  const curr = c as Cluster;
                  return (
                    <OverlayView
                      key={i}
                      position={{
                        lat: curr.location.latitude,
                        lng: curr.location.longitude,
                      }}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                      <>
                        <div
                          onClick={() => {
                            const ltlngBounds = new google.maps.LatLngBounds();
                            for (let d of curr.devices)
                              ltlngBounds.extend({
                                lat: d.randomLocation!.latitude,
                                lng: d.randomLocation!.longitude,
                              });
                            googleMap.fitBounds(ltlngBounds, 100);
                          }}
                          style={{
                            right: "11px",
                            width: "24px",
                            height: "24px",
                            borderRadius: "50%",
                            position: "relative",
                            overflow: "hidden",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              position: "absolute",
                              top: `0%`,
                              left: `0%`,
                              width: `100%`,
                              aspectRatio: "1/1",
                            }}
                          >
                            {isVideo(curr.devices[0].klydo!.loopUrl) ===
                            "image" ? (
                              <img
                                loading="lazy"
                                alt="gif"
                                src={resizeCloudinary(
                                  curr.devices[0].klydo!.loopUrl,
                                  48,
                                )}
                                style={{
                                  width: "auto",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              <video
                                muted={true}
                                src={resizeCloudinary(
                                  curr.devices[0].klydo!.loopUrl,
                                  48,
                                )}
                                loop
                                autoPlay
                                style={{
                                  width: "auto",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            color: "red",
                            borderRadius: "50%",
                            position: "absolute",
                            padding: "2px",
                            top: "-13px",
                            right: "-7px",
                            width: "12px",
                            height: "12px",
                            fontSize: "12px",
                            fontWeight: "bold",
                          }}
                        >
                          {curr.devices.length}
                        </div>
                      </>
                    </OverlayView>
                  );
                }
                const cur = c as Device;
                return (
                  <OverlayView
                    key={i}
                    position={{
                      lat: cur.randomLocation!.latitude,
                      lng: cur.randomLocation!.longitude,
                    }}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  >
                    <div
                      onClick={() => handleMarkerClick(cur)}
                      style={{
                        right: "11px",
                        borderRadius: "50%",
                        width: 24,
                        height: 24,
                        position: "relative",
                        overflow: "hidden",
                        borderStyle: "dotted",
                        borderWidth: "1px",
                        borderColor: "black",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          position: "absolute",
                          top: `0%`,
                          left: `0%`,
                          width: `100%`,
                          aspectRatio: "1/1",
                        }}
                      >
                        {isVideo(cur.klydo!.loopUrl) === "image" ? (
                          <img
                            loading="lazy"
                            alt="gif"
                            src={resizeCloudinary(cur.klydo!.loopUrl, 48)}
                            style={{
                              width: "auto",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <video
                            muted={true}
                            src={resizeCloudinary(cur.klydo!.loopUrl, 48)}
                            loop
                            autoPlay
                            style={{
                              width: "auto",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </OverlayView>
                );
              })}
            {selectedDevice && selectedDeviceReports && (
              <InfoWindow
                position={{
                  lat: selectedDeviceReports.randomLocation!.latitude,
                  lng: selectedDeviceReports.randomLocation!.longitude,
                }}
                onCloseClick={handleCloseInfoWindow}
              >
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <i
                      className={`fa ${selectedDevice.premium && selectedDevice.premium.length ? "fa-star" : "fa-circle"}  ${onlineCheck(selectedDeviceReports.heartbeat) ? "text-success" : "text-danger"}`}
                    >
                      &nbsp;
                    </i>
                    <NavLink
                      to="/admin/devices"
                      onClick={() => {
                        localStorage.setItem(
                          "devicessearch",
                          selectedDevice.idf,
                        );
                      }}
                      style={{ textAlign: "center" }}
                    >
                      {selectedDevice.idf}
                    </NavLink>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {selectedDeviceReports.clocktime &&
                      calcClockTime({
                        offset: selectedDevice.timeOffset?.value,
                        time: selectedDeviceReports.clocktime,
                      })}
                  </div>
                </>
              </InfoWindow>
            )}
          </GoogleMap>
          <div style={{ position: "absolute", bottom: 0, left: 0 }}>
            <button
              onClick={() =>
                handleZoomOnLocation({ lat: 39.113014, lng: -105.358887 }, 5)
              }
            >
              Center On United States
            </button>
            <button
              onClick={() =>
                handleZoomOnLocation({ lat: 48.137154, lng: 11.576124 }, 5)
              }
            >
              Center On Europe
            </button>
            <button
              onClick={() =>
                handleZoomOnLocation({ lat: 31.771959, lng: 35.217018 }, 8)
              }
            >
              Center On Israel
            </button>
          </div>
        </div>
      ) : (
        <Spinner></Spinner>
      )}
    </div>
  );
}

export default KlydoMap;
