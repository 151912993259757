import { Klydo } from "../Types";
import { baseClient } from "./baseRequest";

export type AnalyticsRow = {
  clock: string;
  favorite: boolean;
  clock_time: number;
  display_time: number;
  auto_switch: boolean;
  collection_type: number;
};

export type KlydoSearchFilter = {
  pool?: boolean;
  unlisted?: boolean;
  excludeIds?: string[];
  notScheduled?: boolean;
  notPool?: boolean;
};

export type KlydoApiClient = {
  updateKlydo: (id: string, klydo: Partial<Klydo>) => Promise<void>;
  search: (
    query: string,
    filters?: {
      excludeIds?: string[];
    },
  ) => Promise<{ ids: string[] }>;
  textSearch: (
    query: string,
    limit: number,
    skip: number,
    parseData?: boolean,
    filters?: {
      pool?: boolean;
      unlisted?: boolean;
      inTasks?: boolean;
    },
  ) => Promise<Klydo[]>;
  getKlydosAnalytics: (
    klydoId: string[],
    deviceId: string,
  ) => Promise<AnalyticsRow[]>;
  getKlydoById: (klydoId: string) => Promise<Klydo>;
};

const klydoApiClient: KlydoApiClient = {
  updateKlydo: async (id, klydo) => {
    return baseClient.put({
      url: `/admin/klydos/${id}`,
      body: klydo,
    });
  },
  search: async (query, { excludeIds } = {}) => {
    return baseClient.post<{ ids: string[] }>({
      url: `/klydos/.search/similarity?query=${query}`,
      body: {
        filters: { excludeIds },
      },
    });
  },

  textSearch: async (query, limit, skip, includeMeteData, filters) => {
    return baseClient.post<Klydo[]>({
      url: `/klydos/.search/text?query=${query}`,
      body: {
        limit: limit,
        skip: skip,
        includeMeteData: includeMeteData,
        filters: filters,
      },
    });
  },

  getKlydosAnalytics: async (klydoId: string[], deviceId: string) => {
    return baseClient.post<AnalyticsRow[]>({
      url: `/klydos/get-klydos-analytics`,
      body: {
        klydoId,
        deviceId,
      },
    });
  },

  getKlydoById: async (klydoId: string) => {
    return baseClient.get<Klydo>({
      url: `/klydos/${klydoId}`,
    });
  },
};
export default klydoApiClient;
