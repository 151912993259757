import { useEffect, useState } from "react";
import firebaseService from "firebase_service/firebaseService";
import { User } from "Types";
import { useQuery } from "@tanstack/react-query";
import authorApiClient from "../../api/author";

const useUsers = () => {
  return useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      return authorApiClient.getAllUsers()
    },
  });
};

export default useUsers;
