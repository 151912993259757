import { FieldValue } from "firebase/firestore";

type BaseType = {
  [key: string]: any;
};
export type Klydo = BaseType & {
  createdAt: Date;
  author: string;
  creator: string;
  loopUrl: string;
  name: string;
  theme: Theme;
  updatedAt: Date;
  id: string;
  idf: string;
  time?: number;
  favs?: number;
  tags: string[];
  review: Review;
  zip?: boolean;
  pool: boolean;
  times?: KlydoTimes[];
  featured?: boolean;
  inTask?: boolean;
  crop?: { zoom: number; left: number; top: number };
  featuredTimes?: { start?: Date; end?: Date };
  dials?: boolean;
  unlisted?: boolean;
  public?: boolean;
};
export type timeTypes = "daily" | "weekly" | "monthly" | "yearly";
export type KlydoTimes = {
  type: timeTypes;
  start: string;
  end: string;
  negative: boolean;
};
export type Stats = BaseType & {
  id: string;
  time?: number;
  favs?: number;
};
export type Review = BaseType & {
  started?: Date;
  ended?: Date;
  type: "delete" | "edit" | "publish";
  date?: Date;
  msg?: string;
};
export type ReviewResponse = BaseType & {
  result: Review;
  review: FieldValue;
  edited?: boolean;
};
export type Version = BaseType & {
  id: string;
  version: number;
  description: string;
  date: Date;
  devices: number;
};
export type Tag = BaseType & {
  id: string;
  name: string;
  total: number;
};
export type General = BaseType & {
  last: number;
  public: number;
  beta: number;
  autoUser: boolean;
  autoUserCount: number;
  maxMultipleCommand: number;
};
export type Kpi = {
  activeDevices?: number;
  lastDayRegistered?: number;
  liveDevices?: number;
  connectedDevices?: number;
  versionIssues?: number;
  artists?: number;
  publishedGifs?: number;
  pool?: number;
  favorites?: number;
  time?: number;
  klydosInReview?: number;
  activeEditors?: number;
  artistsInPool?: number;
};
export type Log = BaseType & {
  id: string;
  version: number;
  stack: string;
  message: string;
  error_time: Date;
  dismissed?: boolean;
  debug?: boolean;
};

export type Report = {
  id: string;
  title: string;
  author: string;
  system: string;
  description: string;
  reportTime: any;
  dismissed: boolean;
};

export type Theme = {
  backgroundColor: string;
  handsColor: string;
  pendulumColor: string;
  pendulumRodColor: string;
  dialsColor?: string;
};

export type TableProps = {
  cols: colType[];
  rowBuild: any;
  onScrollDown?: () => void;
  title: string;
  data: Array<any>;
  cancelSort?: boolean;
  buttons?: any;
  actions?: () => JSX.Element;
  onAction?: (selected: Array<any>) => void;
  onSearchChanged?: (currentSearch: string) => void;
  onSelectedChanged?: (
    currSelected: Array<any>,
    currTableData: Array<any>,
  ) => void;
  search: (row: any, val: string) => boolean;
  initSort: string;
  modal?: any;
  handleChangedKlydo?: (klydo: Klydo) => void;
  handleChangedPack?: (pack: SpecialPack) => void;
  id: string;
  actionDisabled?: (selection: Array<any>) => string | undefined;
  shouldHideSearchBar?: boolean;
};

export type colType = {
  scroll?: boolean;
  size?: number;
  noHeadline?: boolean;
  sort: boolean;
  label: string;
  field: string;
};
export const roles = ["clock", "admin", "editor", "viewer"];

export type User = BaseType & {
  uid: string;
  password?: boolean;
  displayName: string;
  created?: Date;
  used?: Date;
  role: "admin" | "editor" | "clock";
  email: string;
  photoURL?: string;
  socialMedia?: SocialNetwork[];
  basicUserInfo?: { tag: string };
};

export type Device = BaseType & {
  clientDetails: any;
  id: string;
  idf: string;
  currentIdf: string;
  heartbeat: Date;
  klydo?: { id: string; loopUrl: string };
  clocktime?: { local: number; server: Date; timeZone?: string };
  timeOffset?: any;
  registerProduct: Date;
  tag?: any;
  version?: any;
  location?: DeviceValue<{
    latitude: number;
    longitude: number;
    country_code: string;
    city: string;
  }>;
  hideDev?: DeviceValue<boolean>;
  user?: any;
  show?: DeviceValue<any>;
  mode?: DeviceValue<any>;
  sort?: DeviceValue<any>;
  sortDown?: DeviceValue<boolean>;
  theme?: any;
  chimes?: DeviceValue<boolean>;
  chimesVol?: DeviceValue<number>;
  ticks?: DeviceValue<boolean>;
  ticksVol?: DeviceValue<number>;
  quietTimesOn?: DeviceValue<boolean>;
  quietTimes?: any;
  auto?: DeviceValue<boolean>;
  autoRestart?: DeviceValue<number>;
  brightness?: any;
  meta?: any;
  nightMode?: DeviceValue<any>;
  nightModeTimes?: DeviceValue<any>;
  premium?: string[];
  autoCreate: boolean;
  beta?: boolean;
  randomLocation?: { latitude: number; longitude: number };
  gift?: {
    klydo: string;
    from: string;
    image?: string;
    date?: string;
    logo: string;
    letter: string;
  };
  order?: string;
};
export type DeviceReport = BaseType &
  Pick<Device, "id" | "heartbeat" | "clocktime" | "randomLocation"> & {
    location: {
      latitude: number;
      longitude: number;
      country_code: string;
      city: string;
    };
  };
export type ScheduledTask = BaseType & {
  date: Date;
  add: Klydo[];
  remove: Klydo[];
  tag: string;
  commands: Array<Command>;
};

export type Command = BaseType & {
  time: Date;
  local: boolean;
  timeout?: number;
  name: string;
  params?: Array<any>;
  id: string;
  devices?: Array<Device> | string[];
  filter?: Array<string>;
};

export type CommandServer = Command & {
  cmdHistory: Array<CommandHistory>;
};

export type CommandHistory = Command & {
  deviceId: string;
  executeTime: Date;
  receiveTime: Date;
  result: string;
};

export type SpecialPack = BaseType & {
  id: string;
  tag: string;
  name: string;
  pendulumImageUrl?: string;
  logoUrl?: string;
  description?: string;
  imageUrl?: string;
  pub: boolean;
  klydos?: string[];
  result?: Review;
  review?: ReviewScheme;
  createdAt?: Date;
  creatorId?: string;
  edited?: boolean;
};

export type Pack = BaseType & {
  id: string;
  name: string;
  cover?: string;
  logo?: string;
  klydoIds?: string[];
  klydos?: Klydo[];
  description?: string;
  createdAt?: Date;
  updatedAt?: Date;
  creatorId?: string;
};

export type DeviceValue<T> = {
  time: any;
  value: T;
};

export type ReviewScheme = {
  type: "delete" | "edit" | "publish";
  date: Date;
  msg?: string;
  approved?: boolean;
};

export type SocialNetwork = { network: string; link: string };
