import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { KlydoSearchFilter } from "../../api/klydo";
import klydoApiClient from "../../api/klydo";
import { Klydo } from "../../Types";
import { useEffect, useRef } from "react";

const useKlydosByText = (
  query: string,
  limit: number,
  filters?: KlydoSearchFilter,
  includeMeteData?: boolean,
) => {
  const queryClient = useQueryClient();
  const dataRef = useRef<Klydo[]>();

  const { data, fetchNextPage, ...rest } = useInfiniteQuery<Klydo[], Error>({
    queryKey: ["klydos", query, filters?.pool, filters?.unlisted, filters?.notScheduled, filters?.notPool, limit],
    queryFn: async ({ pageParam }) => {
      const { skip = 0 } = pageParam as { skip?: number };
      const res = await klydoApiClient.textSearch(
        query,
        limit,
        skip,
        includeMeteData || false,
        filters || {},
      );

      res.forEach((klydo) => {
        queryClient.setQueryData(["klydo", klydo.id], klydo);
      });

      return res;
    },
    getNextPageParam: (lastPage, allPages) => {
      const currentLength = allPages.flat().length;
      return currentLength < limit ? undefined : { skip: currentLength };
    },
    initialPageParam: { skip: 0 },
  });

  const flatData = data?.pages.flat();

  useEffect(() => {
    dataRef.current = flatData;
  }, [flatData]);

  return { data: flatData, dataRef, fetchNextPage, ...rest };
};

export default useKlydosByText;
