import { ChangeEvent, useEffect, useRef, useState } from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  InputGroup,
  Form,
} from "react-bootstrap";
import { colType, TableProps } from "../../Types";

import { FixedSizeList as List } from "react-window";
import DSDialog from "../../views/system-design/DSDialog";
let timeout: any = -1;

function MsTable(props: TableProps) {
  let id = props.id;
  const [selected, setSelected] = useState<Array<any>>([]);
  const [localField, setLocalField] = useState<string>(
    localStorage.getItem(id) || props.initSort || "",
  );
  const [localSearch, setLocalSearch] = useState<string>(
    localStorage.getItem(id + "search") || "",
  );
  const [reversed, setReversed] = useState<boolean>(
    localStorage.getItem(id + "Reverse")
      ? localStorage.getItem(id + "Reverse") === "true"
      : false,
  );
  const [dataState, setDataState] = useState<Array<any>>(props.data);
  const [showModal, setShowModal] = useState(false);
  const [chosenRow, setChosenRow] = useState<colType | undefined>(undefined);
  const [showActions, setShowActions] = useState(false);
  const [size, setSize] = useState({ width: 0, height: 0, colWidth: 150 });
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    localStorage.setItem(id, localField);
    localStorage.setItem(id + "Reverse", reversed ? "true" : "false");

    let localData: Array<any> = props.data;
    if (localSearch && !props.shouldHideSearchBar) {
      localData = localData.filter((i: any) => props.search(i, localSearch));
    }
    if (localField && !props.cancelSort) {
      localData = localData.sort((a: any, b: any) => {
        let aa = a[localField];
        let bb = b[localField];
        if (localField === "reviewDate") {
          aa = a["review"].date;
          bb = b["review"].date;
        }
        if (localField === "location") {
          aa = a["location"]?.value?.country + a["location"]?.value?.city;
          bb = b["location"]?.value?.country + b["location"]?.value?.city;
        }
        const aType = typeof aa;
        const bType = typeof bb;
        if (
          (aa === null || aType === "undefined") &&
          (bb === null || bType === "undefined")
        )
          return 0;
        else if (aa === null || aType === "undefined") return 1;
        else if (bb === null || bType === "undefined") return -1;
        else {
          if (aType === "number" && bType === "number") return bb - aa;
          else if (aType === "string" && bType === "string") {
            return aa.localeCompare(bb);
          }
          if (aa && bb) {
            if (aType === "object") {
              if (localField === "favorites") {
                const laa = aa ? aa.length : 0;
                const lbb = bb ? bb.length : 0;
                return laa - lbb;
              } else if (aa.__proto__ === Date.prototype) {
                return (bb as Date).getTime() - (aa as Date).getTime();
              }
            }
          } else if (!aa) return 1;
          return -1;
        }
      });
      if (reversed) localData = localData.reverse();
      if (props.onSearchChanged && localData.length > 0)
        props.onSearchChanged(localSearch);
    }
    setDataState([...localData]);
    return () => window.removeEventListener("resize", handleResize);
  }, [reversed, localField, localSearch, props.data]);

  function handleResize() {
    if (ref.current != null) {
      const xy = ref.current.getBoundingClientRect();
      let width = xy.width;
      const cols =
        props.cols.reduce((a, b) => a + (b.size ? b.size : 150), 0) + 10;
      const scroll = width < cols;
      let colWidth = 150;
      if (cols < width) {
        const extra = width - cols;
        colWidth += extra / props.cols.filter((s) => !s.size).length;
      } else {
        width = cols;
      }
      let height = xy.height - 1 - (scroll ? 16 : 0);
      setSize({ width: width, height: height, colWidth: colWidth });
    }
  }

  const Row2 = ({ index, style }: any): any => {
    const r = dataState[index];
    const dt = props.rowBuild(r, index);
    if (props.actions && !dt.choose) {
      dt.choose = (
        <input
          disabled={showActions}
          id={"check" + r.id.toString()}
          defaultChecked={selected.findIndex((f) => f.id === r.id) !== -1}
          type="checkbox"
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            e.stopPropagation();
            if (e.target.checked) selected.push(r);
            else selected.splice(selected.indexOf(r), 1);
            setSelected([...selected]);
            if (props.onSelectedChanged)
              props.onSelectedChanged(selected, dataState);
          }}
        />
      );
    }

    return (
      <div
        className="table-row"
        style={{ ...style, display: "flex", alignItems: "center" }}
        key={index}
        onClick={() => {
          if (props.modal && localStorage.getItem("tableId") !== id) {
            setChosenRow(r);
            setShowModal(true);
            localStorage.removeItem("tableId");
          }
        }}
      >
        {props.actions && (
          <div style={{ display: "inline-block", width: 32 }} key={index}>
            <>{dt["choose"]}</>
          </div>
        )}
        {props.cols.map((col: colType, index: number) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: col.size ? col.size : size.colWidth + "px",
              ...(col.scroll ? { overflowY: "scroll", height: "100%" } : {}),
            }}
            key={index}
          >
            <>{dt[col.field]}</>
          </div>
        ))}
      </div>
    );
  };
  function handleCheckAll(event: ChangeEvent<HTMLInputElement>): void {
    let tmp = [...selected];
    if (event.target.checked) {
      for (let d of dataState) {
        if (!tmp.includes(d)) tmp.push(d);
      }
    } else {
      tmp = tmp.filter((curr) => !dataState.includes(curr));
    }
    setSelected([...tmp]);
    if (props.onSelectedChanged) props.onSelectedChanged(tmp, dataState);
  }

  const actionDisabled = props.actionDisabled && props.actionDisabled(selected);
  return (
    <>
      <Container
        fluid
        style={{ display: "flex", flexFlow: "column", flex: "1 1 auto" }}
      >
        <Card
          className="card-plain table-plain-bg"
          style={{
            margin: 0,
            display: "flex",
            flexFlow: "column",
            flex: "1 1 auto",
          }}
        >
          <Card.Header
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Card.Title as="h4">{props.title}</Card.Title>
            {props.actions && selected.length > 0 && (
              <>
                {!showActions && (
                  <Button
                    disabled={!!actionDisabled}
                    title={
                      actionDisabled || "Send commands to selected devices"
                    }
                    style={{
                      display: "inline-block",
                      color: "black",
                      pointerEvents: "auto",
                    }}
                    className="nc-icon nc-send btn"
                    onClick={() => {
                      setShowActions(true);
                      setDataState([...selected]);
                      if (props.onAction) props.onAction(selected);
                    }}
                  >
                    {" "}
                    {selected.length}
                  </Button>
                )}

                {showActions && (
                  <>
                    {props.actions()}
                    <Button
                      disabled={!!actionDisabled}
                      title={"Exit actions and continue selecting"}
                      style={{
                        marginBottom: "5px",
                        fontSize: "80%",
                        height: "fit-content",
                        paddingTop: "6px",
                        paddingBottom: "6px",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setShowActions(false);
                        setSelected([]);
                        if (!props.shouldHideSearchBar) {
                          setDataState([
                            ...props.data.filter((i: any) =>
                              props.search(i, localSearch),
                            ),
                          ]);
                        }
                      }}
                    >
                      Exit
                    </Button>
                  </>
                )}
              </>
            )}
            {props.buttons && <>{props.buttons}</>}
          </Card.Header>
          <Card.Body
            className="table-full-width table-responsive"
            style={{
              padding: 0,
              flex: "1 1 auto",
              display: "flex",
              flexFlow: "column",
              overflow: "auto",
            }}
          >
            {!props.shouldHideSearchBar && (
              <InputGroup>
                <InputGroup.Text>
                  <i className="nc-icon nc-zoom-split"></i>
                </InputGroup.Text>
                <Form.Control
                  disabled={showActions}
                  defaultValue={localSearch}
                  type="text"
                  onChange={(e) => {
                    clearTimeout(timeout);
                    timeout = setTimeout(() => {
                      let search = e.target.value.toLowerCase();
                      localStorage.setItem(id + "search", search);
                      setLocalSearch(e.target.value.toLowerCase());
                    }, 100);
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Search
                </Form.Control.Feedback>
              </InputGroup>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: size.width,
              }}
            >
              {props.actions && (
                <label
                  style={{ width: 32, marginTop: "15px", textAlign: "center" }}
                >
                  All
                  <input
                    disabled={dataState.length == 0 || showActions}
                    type="checkbox"
                    checked={dataState.every((curr) => selected.includes(curr))}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => handleCheckAll(e)}
                  />
                </label>
              )}
              {props.cols.map((col: colType, index: number) => (
                <div
                  style={{
                    width: col.size ? col.size : size.colWidth + "px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  key={index}
                >
                  {col.noHeadline ? (
                    ""
                  ) : (
                    <Button
                      variant="default"
                      style={{ textTransform: "uppercase", fontSize: 12 }}
                      onClick={
                        col.sort
                          ? () => {
                              if (col.field !== localField) {
                                setReversed(false);
                                setLocalField(col.field);
                              } else setReversed(!reversed);
                            }
                          : undefined
                      }
                    >
                      <Row className="justify-content-between flex-nowrap">
                        {col.label}
                        {col.sort && (
                          <Col className="ml-2">
                            {col.field !== localField || reversed ? (
                              <div
                                className="nc-icon nc-stre-up"
                                style={{ display: "grid" }}
                              />
                            ) : (
                              ""
                            )}
                            {col.field !== localField || !reversed ? (
                              <div
                                className="nc-icon nc-stre-down"
                                style={{ display: "grid" }}
                              />
                            ) : (
                              ""
                            )}
                          </Col>
                        )}
                      </Row>
                    </Button>
                  )}
                </div>
              ))}
            </div>
            <div ref={ref} style={{ flex: "1 1 auto" }}>
              <List
                onItemsRendered={(o) => {
                  if (
                    props.onScrollDown &&
                    o.overscanStopIndex === dataState.length - 1
                  ) {
                    props.onScrollDown();
                  }
                }}
                itemCount={dataState.length}
                itemSize={105}
                height={size.height}
                width={size.width}
              >
                {Row2}
              </List>
              <p style={{ position: "absolute", bottom: 0, right: 0 }}>
                rows: {dataState.length}
              </p>
            </div>
          </Card.Body>
        </Card>
      </Container>
      {props.modal && (
        <DSDialog
          fullWidth
          maxWidth="lg"
          open={showModal}
          onClose={() => setShowModal(false)}
          //TODO: remove zIndex
          sx={{ zIndex: 1 }}
        >
          <props.modal
            setShowModal={setShowModal}
            row={chosenRow}
            fixedData={props.rowBuild}
            data={props.data}
            handleChanged={props.handleChangedKlydo ?? props.handleChangedPack}
          />
        </DSDialog>
      )}
    </>
  );
}

export default MsTable;
