import { Klydo } from "../Types";
import { baseClient } from "./baseRequest";

export type GiftCategory = {
  _id: string;
  name: string;
  categoryImageUrl: string;
  klydoIds: string[];
  klydos?: Klydo[];
};

export type ClockGift = {
  klydo: Klydo;
  from: string;
  image?: string;
  date?: string;
  logo: string;
  letter: string;
};

export type OrderInfo = {
  email: string;
  first_name: string;
  last_name: string;
  info: {
    serial_number: string[];
  }[];
  daysToConversion: number;
  firstVisit: {
    landingPage: string;
    occurredAt: string;
    referrerUrl: string;
  };
};

export type Gift = {
  orderId: string;
  createdAt: Date;
  clocksIds?: string[];
  senderName: string;
  greetingContent: string;
  klydoId: string;
  date?: number;
  senderImageUrl?: string;
};

export type EditGiftInput = { orderId: string } & Pick<Gift, "clocksIds">;

export type GiftCategoriesClient = {
  getAllCategories: () => Promise<GiftCategory[]>;
  createCategory: (category: Partial<GiftCategory>) => Promise<string>;
  getOrderInfo(orderId: string): Promise<OrderInfo>;
  deleteOrderGift: (orderId: string) => Promise<void>;

  updateCategory: (
    categoryId: string,
    category: Partial<GiftCategory>,
  ) => Promise<void>;
  removeCategory: (categoryId: string) => Promise<void>;
  deleteClockGift: (clockId: string) => Promise<void>;
  getClockGift: (clockId: string) => Promise<ClockGift | null>;
  listGiftOrders: () => Promise<Gift[]>;
  editOrderGift: (input: EditGiftInput) => Promise<Gift>;
};

const giftCategoriesClient: GiftCategoriesClient = {
  editOrderGift: async (input) => {
    return baseClient.post({
      url: "/gift/edit-order-gift",
      body: input,
    });
  },
  listGiftOrders: async () => {
    return baseClient.get<Gift[]>({
      url: "/gift/list-order-gifts",
    });
  },
  getOrderInfo: async (orderId) => {
    return baseClient.get({
      url: `/orders/${orderId}`,
    });
  },
  deleteOrderGift: async (orderId: string) => {
    return baseClient.delete({
      url: `/gift/${orderId}`,
    });
  },
  deleteClockGift: async (clockId: string) => {
    return baseClient.delete({
      url: `/gift?clockId=${clockId}`,
    });
  },
  getClockGift: async (clockId: string) => {
    return baseClient.get<ClockGift | null>({
      url: "/gift/get-clock-gift",
      params: {
        ["clock-id"]: clockId,
      },
    });
  },
  getAllCategories: async () => {
    return baseClient.get<GiftCategory[]>({
      url: "/gift-categories",
    });
  },
  createCategory: async (category) => {
    return baseClient.post<string>({
      url: "/gift-categories",
      body: category,
    });
  },
  updateCategory: async (categoryId, category) => {
    return baseClient.put({
      url: `/gift-categories/${categoryId}`,
      body: category,
    });
  },
  removeCategory: async (categoryId) => {
    return baseClient.delete({
      url: `/gift-categories/${categoryId}`,
    });
  },
};

export default giftCategoriesClient;
