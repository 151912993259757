/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Modal,
  Container,
  Card,
  Row,
  Col,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import { download, myColor, secondsToTime } from "../../../utils/util";
import { useEffect, useState } from "react";
import { FaEdit, FaRegClock, FaRegHeart } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import KlydoFrames from "../../KlydoFrames";
import { colType, Klydo, ScheduledTask, Theme, User } from "../../../Types";
import firebaseService from "../../../firebase_service/firebaseService";
import ButtonLoader from "../../../components/Widgets/ButtonLoader";
import Editable from "../../../components/Widgets/Editable";
import SimpleSwitch from "../../../components/Widgets/SimpleSwitch";
import TimeControl from "../../../components/Widgets/TimeControl";
import TagList from "../../../components/Widgets/TagList";
import ClockView from "../../../components/Widgets/ClockView";
import MsTable from "../../../components/Widgets/Table";
import ConfirmationSwitch from "../../ConfirmationSwitch";
import useKlydo from "../../../components/hooks/useKlydo";
import useKlydoMutation from "../hooks/useKlydoMutation";

const collectionTypeOptions = ["All", "Favorite", "My Klydos", "Premium"];
function KlydoModal(props: {
  klydo: Klydo;
  setShowModal: (show: boolean) => void;
}) {
  const { setShowModal, klydo } = props;
  const { mutateAsync: updateKlydo } = useKlydoMutation(klydo.id);
  const [users, setUsers] = useState<Array<User>>();
  const [scheduledPool, setScheduledPool] = useState<Array<ScheduledTask>>();
  const [cahngeName, setCahngeName] = useState(false);
  const [klydoName, setKlydoName] = useState(klydo.name);
  const [cahngeCreator, setCahngeCreator] = useState(false);
  const [creatorId, setCreatorId] = useState(klydo.creator);
  const creator = klydo.creator;
  const [showKlydoAnalyticsModal, setShowKlydoAnalyticsModal] = useState(false);
  const [showFrames, setShowFrames] = useState(false);
  const [tableId] = useState("klydoViews");
  const [klydoAnalyticData, setKlydoAnalyticData] = useState<
    Array<{
      deviceId: string;
      clockTime: Date;
      displayTime: number;
      autoSwitch: boolean;
      favorite: boolean;
      collectionType: string;
    }>
  >();
  const dialsColor = klydo.theme.dialsColor || klydo.theme.handsColor;

  const showDials = klydo.dials ?? true;
  const updateTheme = (updateObject: Partial<Theme>) =>
    updateKlydo({ theme: { ...klydo.theme, ...updateObject } });

  const updateTags = async (tags: string[]) => {
    await Promise.all([
      firebaseService.updateTags(tags),
      updateKlydo({ tags }),
    ]);
  };
  const isPoolValidAction = () => {
    if (!klydo.pool && (klydo.unlisted || !klydo.public)) {
      return "Not alowed to set pool for not public klydos or unlisted klydos";
    }
    return "";
  };
  const isUnlistedValidAction = () => {
    if (!klydo.unlisted && (klydo.pool || klydo.public)) {
      return "Not alowed to set unlisted for not public klydos or pool klydos";
    }
    return "";
  };
  const isPublicdValidAction = () => {
    if (!klydo.public && klydo.unlisted) {
      return "Not alowed to set public for unlisted klydos";
    }
    if (klydo.public && klydo.pool) {
      return "Not alowed to set private for pool klydos";
    }
    return "";
  };

  const toggleUnlistedStatus = async (status: boolean) => {
    try {
      updateKlydo({ unlisted: status });
    } catch (error) {
      console.error("Error updating unlisted status:", error);
    }
  };

  const togglePublicStatus = async (status: boolean) => {
    try {
      updateKlydo({ public: status });
    } catch (error) {
      console.error("Error updating unlisted status:", error);
    }
  };

  const togglePoolStatus = async (status: boolean) => {
    try {
      updateKlydo({ pool: status });
    } catch (error) {
      console.error("Error updating unlisted status:", error);
    }
  };

  const creatorsList = users
    ?.filter((u) => u.role === "editor")
    .map((u) => ({ value: u.uid as string, label: u.displayName as string }));
  useEffect(() => {
    firebaseService.getData<User>("users").then(setUsers);
    firebaseService
      .getData<ScheduledTask>("scheduledpool")
      .then(setScheduledPool);
  }, []);
  function getContrastYIQ(hexcolor: string) {
    if (!hexcolor) return "black";
    const r = parseInt(hexcolor.substring(1, 3), 16);
    const g = parseInt(hexcolor.substring(3, 5), 16);
    const b = parseInt(hexcolor.substring(5, 7), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? "black" : "white";
  }

  function toMyTimeString(time: number): string {
    if (time < 100) return time + "s";
    if (time / 60 < 100) return Math.floor(time / 60).toLocaleString() + "m";
    if (time / 3600 < 100)
      return Math.floor(time / 3600).toLocaleString() + "h";
    return Math.floor(time / (24 * 3600)).toLocaleString() + "d";
  }

  const tableColumn: Array<colType> = [
    {
      sort: true,
      label: "Clock Time",
      field: "clockTime",
    },
    {
      size: 170,
      sort: true,
      label: "Display Time (mos:day:hrs:min:sec)",
      field: "displayTime",
    },
    {
      sort: true,
      label: "Device Id",
      field: "deviceId",
    },
    {
      sort: true,
      label: "Auto Switch",
      field: "autoSwitch",
    },
    {
      sort: true,
      label: "Favorite",
      field: "favorite",
    },
    {
      sort: true,
      label: "Collection Type",
      field: "collectionType",
    },
  ];
  const tableRows = (
    r: {
      deviceId: string;
      clockTime: Date;
      displayTime: number;
      autoSwitch: boolean;
      favorite: boolean;
      collectionType: number;
    },
    key: string,
  ) => {
    console.log(
      "autos: " +
        r.autoSwitch +
        " fav: " +
        r.favorite +
        " deviceId: " +
        r.deviceId,
    );
    return {
      clockTime: r.clockTime.toLocaleString("en-GB"),
      displayTime: secondsToTime(r.displayTime),
      deviceId: (
        <NavLink
          to="/admin/devices"
          onClick={() => {
            localStorage.setItem("devicessearch", r.deviceId);
          }}
        >
          {r.deviceId}
        </NavLink>
      ),
      autoSwitch: r.autoSwitch.toString(),
      favorite: r.favorite.toString(),
      collectionType: collectionTypeOptions[r.collectionType],
      key: key,
    };
  };

  return (
    <>
      <Modal.Header style={{ marginLeft: 24, marginTop: 0, marginBottom: 0 }}>
        <h4>Klydo Info</h4>
        <i
          className="nc-icon nc-simple-remove"
          style={{ marginTop: 15 }}
          onClick={() => setShowModal(false)}
        ></i>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row>
            <Col lg="6" sm="6">
              <Card
                className="card-stats shadow"
                style={{ background: myColor }}
              >
                <Card.Header
                  as="h4"
                  style={{ marginTop: 0, background: myColor }}
                >
                  Info
                </Card.Header>
                <hr style={{ margin: 0 }} />
                <Card.Body>
                  <Row>
                    <Col className="pr-1" md="12">
                      <h5 className="pl-2" style={{ display: "inline" }}>
                        <b>Klydo ID:</b> {klydo.id}
                      </h5>
                    </Col>
                    <Col className="pr-1" md="12">
                      <h5 className="pl-2" style={{ display: "inline" }}>
                        <b>Friendly ID:</b> {klydo.idf}
                      </h5>
                    </Col>
                    <Col className="pr-1" md="12" style={{ marginBottom: 12 }}>
                      <h5
                        className="pl-2"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <b>Name:</b>
                        {cahngeName ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "500px",
                              minWidth: "200px",
                              maxWidth: "700px",
                            }}
                          >
                            <Form.Control
                              style={{ marginLeft: "12px" }}
                              value={klydoName}
                              onChange={(e) => setKlydoName(e.target.value)}
                            ></Form.Control>
                            <ButtonLoader
                              variant="outline-dark"
                              title={<i className="nc-icon nc-check-2"></i>}
                              onClick={() => {
                                return updateKlydo({ name: klydoName });
                              }}
                            />
                            <i
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                              className="nc-icon nc-simple-remove"
                              onClick={() => {
                                setKlydoName(klydo.name);
                                setCahngeName(false);
                              }}
                            />
                          </div>
                        ) : (
                          <>
                            {klydoName}
                            <FaEdit
                              size={20}
                              style={{ marginLeft: 10 }}
                              onClick={(e: any) => {
                                e.stopPropagation();
                                setCahngeName(true);
                              }}
                            />
                          </>
                        )}
                      </h5>
                    </Col>
                    <Col className="pr-1" md="12">
                      <div
                        className="pl-2"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        {klydo.favs && (
                          <div
                            title="Total likes"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              marginRight: 12,
                            }}
                          >
                            <FaRegHeart size={20} />
                            <p
                              style={{
                                fontSize: 8,
                                position: "relative",
                                top: -15,
                              }}
                            >
                              {klydo.favs}
                            </p>
                          </div>
                        )}
                        {klydo.time && (
                          <div
                            title="Total viewing time"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <FaRegClock />
                            <p style={{ fontSize: 8 }}>
                              {toMyTimeString(klydo.time)}
                            </p>
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col className="pr-1" md="12">
                      <h5 className="pl-2" style={{ display: "inline" }}>
                        <b>Created At:</b>{" "}
                        {klydo.createdAt.toLocaleString("en-GB")}
                      </h5>
                    </Col>
                    <Col className="pr-1" md="12" style={{ marginBottom: 22 }}>
                      <h5 className="pl-2" style={{ display: "inline" }}>
                        <b>Update At:</b>{" "}
                        {klydo.updatedAt?.toLocaleString("en-GB")}
                      </h5>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Card
                className="card-stats shadow"
                style={{ background: myColor }}
              >
                <Card.Header
                  as="h4"
                  style={{ marginTop: 0, background: myColor }}
                >
                  Author
                </Card.Header>
                <hr style={{ margin: 0 }} />
                <Card.Body>
                  <Col className="pr-1" md="12">
                    {cahngeCreator ? (
                      <>
                        <h5 className="pl-2" style={{ display: "flex" }}>
                          <b
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            Author:
                          </b>
                          <Form.Select
                            style={{ marginLeft: "12px" }}
                            value={creatorId}
                            onChange={(e) => setCreatorId(e.target.value)}
                          >
                            {creatorsList
                              ?.sort((a, b) => a.label.localeCompare(b.label))
                              .map((c) => (
                                <option
                                  value={c.value}
                                  label={c.label}
                                ></option>
                              ))}
                          </Form.Select>
                          <ButtonLoader
                            variant="outline-dark"
                            title={<i className="nc-icon nc-check-2"></i>}
                            onClick={async () => {
                              updateKlydo({ creator: creatorId });
                              setCahngeCreator(false);
                            }}
                          />
                          <i
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                            className="nc-icon nc-simple-remove"
                            onClick={() => setCahngeCreator(false)}
                          />
                        </h5>
                      </>
                    ) : (
                      <>
                        <b>Author:</b>

                        <NavLink
                          to="/admin/users"
                          className="pl-2"
                          style={{ display: "inline" }}
                          onClick={() => {
                            localStorage.setItem("userssearch", creatorId);
                          }}
                        >
                          {users?.find((i) => i.uid === creator)?.displayName}
                        </NavLink>
                        <FaEdit
                          size={20}
                          style={{ marginLeft: 10 }}
                          onClick={(e: any) => {
                            e.stopPropagation();
                            setCahngeCreator(true);
                          }}
                        />
                      </>
                    )}
                  </Col>
                  <Col className="pr-1" md="12" style={{ marginBottom: 22 }}>
                    <h5 className="pl-2" style={{ display: "inline" }}>
                      <b>Creator ID:</b>
                      {creator}
                    </h5>
                  </Col>
                </Card.Body>
              </Card>
              <Card
                className="card-stats shadow"
                style={{ background: myColor }}
              >
                <Card.Header
                  as="h4"
                  style={{ marginTop: 0, background: myColor }}
                >
                  Theme
                </Card.Header>
                <hr style={{ margin: 0 }} />
                <Card.Body>
                  <Col className="pr-1" md="12" style={{ marginBottom: 22 }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <b>Background Color:</b>
                      <Editable
                        title="color"
                        default={klydo.theme.backgroundColor}
                        onSet={(value) =>
                          updateTheme({ backgroundColor: value })
                        }
                        editIcon={
                          <FaEdit size={20} style={{ marginLeft: 10 }} />
                        }
                        viewAs={
                          <span
                            className="dot"
                            style={{
                              backgroundColor: klydo.theme.backgroundColor,
                              color: getContrastYIQ(
                                klydo.theme.backgroundColor,
                              ),
                            }}
                          >
                            {klydo.theme.backgroundColor}
                          </span>
                        }
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <b>Hands Color:</b>
                      <Editable
                        title="color"
                        default={klydo.theme.handsColor}
                        onSet={(value) => updateTheme({ handsColor: value })}
                        editIcon={
                          <FaEdit size={20} style={{ marginLeft: 10 }} />
                        }
                        viewAs={
                          <span
                            className="dot"
                            style={{
                              backgroundColor: klydo.theme.handsColor,
                              color: getContrastYIQ(klydo.theme.handsColor),
                            }}
                          >
                            {klydo.theme.handsColor}
                          </span>
                        }
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <b>Pendulum Color:</b>
                      <Editable
                        title="color"
                        default={klydo.theme.pendulumColor}
                        onSet={(value) => updateTheme({ pendulumColor: value })}
                        editIcon={
                          <FaEdit size={20} style={{ marginLeft: 10 }} />
                        }
                        viewAs={
                          <span
                            className="dot"
                            style={{
                              backgroundColor: klydo.theme.pendulumColor,
                              color: getContrastYIQ(klydo.theme.pendulumColor),
                            }}
                          >
                            {klydo.theme.pendulumColor}
                          </span>
                        }
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <b>Pendulum Rod Color:</b>
                      <Editable
                        title="color"
                        default={klydo.theme.pendulumRodColor}
                        onSet={(value) =>
                          updateTheme({ pendulumRodColor: value })
                        }
                        editIcon={
                          <FaEdit size={20} style={{ marginLeft: 10 }} />
                        }
                        viewAs={
                          <span
                            className="dot"
                            style={{
                              backgroundColor: klydo.theme.pendulumRodColor,
                              color: getContrastYIQ(
                                klydo.theme.pendulumRodColor,
                              ),
                            }}
                          >
                            {klydo.theme.pendulumRodColor}
                          </span>
                        }
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ display: "flex" }}>
                        <b>Dials Color:</b>
                        {showDials && (
                          <Editable
                            title="color"
                            default={dialsColor}
                            onSet={(value) => {
                              const theme = klydo.theme;
                              return updateKlydo({
                                theme: { ...theme, dialsColor: value },
                              });
                            }}
                            editIcon={
                              <FaEdit size={20} style={{ marginLeft: 10 }} />
                            }
                            viewAs={
                              <span
                                className="dot"
                                style={{
                                  backgroundColor: dialsColor,
                                  color: getContrastYIQ(dialsColor),
                                }}
                              >
                                {dialsColor}
                              </span>
                            }
                          />
                        )}
                      </div>
                      <SimpleSwitch
                        title="Dials"
                        on={undefined}
                        default={showDials}
                        onClick={() => updateKlydo({ dials: !showDials })}
                      />
                    </div>
                  </Col>
                </Card.Body>
              </Card>
              <Card
                className="card-stats shadow"
                style={{ background: myColor }}
              >
                <Card.Header
                  as="h4"
                  style={{ marginTop: 0, background: myColor }}
                >
                  Times
                </Card.Header>
                <hr style={{ margin: 0 }} />
                <Card.Body>
                  <TimeControl klydo={klydo} editable={true} />
                </Card.Body>
              </Card>
              <Card
                className="card-stats shadow"
                style={{ background: myColor, paddingBottom: 15 }}
              >
                <Card.Header
                  as="h4"
                  style={{ marginTop: 0, background: myColor }}
                >
                  Tags
                </Card.Header>
                <hr style={{ margin: 0 }} />
                <Card.Body>
                  <TagList klydo={klydo} onSave={(l) => updateTags(l)} />
                </Card.Body>
              </Card>
              {!!klydo.tags?.find((tag) => tag.includes("$p")) && (
                <Card
                  className="card-stats shadow"
                  style={{ background: myColor, paddingBottom: 15 }}
                >
                  <Card.Header
                    as="h4"
                    style={{ marginTop: 0, background: myColor }}
                  >
                    Packs
                  </Card.Header>
                  <hr style={{ margin: 0 }} />
                  <Card.Body>
                    <TagList
                      tagList={klydo.tags.filter((tag) => tag.includes("$p"))}
                      onSave={() => new Promise<void>((v) => v())}
                    />
                  </Card.Body>
                </Card>
              )}
              <Card
                className="card-stats shadow"
                style={{ background: myColor, paddingBottom: 15 }}
              >
                <Card.Header
                  as="h4"
                  style={{ marginTop: 0, background: myColor }}
                >
                  Pool Scheduling
                </Card.Header>
                <hr style={{ margin: 0 }} />
                <Card.Body>
                  <>
                    <div key={77773} className="table-row">
                      <p>
                        {klydo.pool
                          ? "Currently in Pool"
                          : "Currently Not in Pool"}
                      </p>
                    </div>
                    {scheduledPool?.map((s, i) => {
                      if (
                        !(s.add as Klydo[]).includes(klydo) &&
                        !(s.remove as Klydo[]).includes(klydo)
                      )
                        return <></>;
                      return (
                        <div key={i} className="table-row">
                          <p>
                            {((s.add as Klydo[]).includes(klydo) ? "+" : "-") +
                              s.date.toLocaleDateString("en-GB")}
                          </p>
                        </div>
                      );
                    })}
                  </>
                </Card.Body>
              </Card>
            </Col>

            <Col lg="6" sm="6">
              <Col className="pr-1" md="12" style={{ marginBottom: 22 }}>
                <div
                  style={{
                    width: "476px",
                    height: "785px",
                    position: "relative",
                  }}
                >
                  <i
                    style={{
                      top: "70px",
                      right: "70px",
                      position: "absolute",
                      color: "white",
                      zIndex: 5,
                      fontWeight: "bold",
                      marginLeft: "10px",
                    }}
                    className="nc-icon nc-cloud-download-93 hover-pointer"
                    onClick={() => {
                      download(klydo.loopUrl, klydo.idf);
                    }}
                  />
                  <ClockView
                    loopUrl={klydo.loopUrl}
                    themeID={klydo.id}
                    timeShift={0}
                    handsColor={klydo.theme.handsColor}
                    pendulumColor={klydo.theme.pendulumColor}
                    pendulumRodColor={klydo.theme.pendulumRodColor}
                    backgroundColor={klydo.theme.backgroundColor}
                    dialsColor={dialsColor}
                    crop={klydo.crop}
                    showDials={showDials}
                  />
                </div>
              </Col>
              <Col className="pr-1" md="12" style={{ marginBottom: 22 }}>
                <a
                  className="pl-2"
                  style={{ display: "inline" }}
                  target="_blank"
                  rel="noreferrer"
                  href={"https://klydo-editor.web.app/klydo/" + klydo.id}
                >
                  <b>Open in Editor</b>
                </a>
              </Col>
              <Button
                style={{ display: "inline-block", marginTop: "15px" }}
                onClick={() => {
                  setShowKlydoAnalyticsModal(true);
                  firebaseService
                    .getKlydoAnalyticsByKlydo([klydo.id])
                    .then((v) => {
                      console.log(v);
                      setKlydoAnalyticData(
                        v.map((a) => ({
                          deviceId: a.clock,
                          clockTime: new Date(a.clock_time),
                          displayTime: a.display_time,
                          autoSwitch: a.auto_switch,
                          favorite: a.favorite,
                          collectionType: a.collection_type,
                        })),
                      );
                    });
                }}
              >
                Klydo views
              </Button>
              <Button
                style={{
                  display: "inline-block",
                  marginTop: "15px",
                  marginLeft: "2px",
                }}
                onClick={() => setShowFrames(true)}
              >
                Show Frames
              </Button>
              <Row>
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "15px",
                    }}
                  >
                    <b>{" Unlist: "}</b>
                    <ConfirmationSwitch
                      status={!!klydo.unlisted}
                      action={toggleUnlistedStatus}
                      onMessage="Are you sure... this will remove the klydo to all clocks"
                      offMessage="This will make the Klydo available for all actions (pool | public)."
                      disabledReason={isUnlistedValidAction()}
                    />
                  </div>
                </>
              </Row>
              <Row>
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "15px",
                    }}
                  >
                    <b>{" Public: "}</b>
                    <ConfirmationSwitch
                      status={!!klydo.public}
                      action={togglePublicStatus}
                      onMessage="Are you sure you want to turn this Klydo to public?"
                      offMessage="Are you sure you want to turn this Klydo to private?"
                      disabledReason={isPublicdValidAction()}
                    />
                  </div>
                </>
              </Row>
              <Row>
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "15px",
                    }}
                  >
                    <b>{" Pool: "}</b>
                    <ConfirmationSwitch
                      status={!!klydo.pool}
                      action={togglePoolStatus}
                      onMessage="Are you sure... this will add the klydo to all clocks"
                      offMessage="This will remove the klydo from the clocks"
                      disabledReason={isPoolValidAction()}
                    />
                  </div>
                </>
              </Row>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <div className="modal-footer justify-content-end">
        <Button
          className="btn-simple right"
          type="button"
          variant="link"
          onClick={() => setShowModal(false)}
        >
          OK
        </Button>
      </div>
      {showFrames && (
        <KlydoFrames
          onHide={() => setShowFrames(false)}
          klydoLoopUrl={klydo.loopUrl}
        />
      )}
      {showKlydoAnalyticsModal && (
        <Modal
          dialogClassName="custom-dialog-style"
          contentClassName="custom-modal-style"
          show={showKlydoAnalyticsModal}
          onHide={() => setShowKlydoAnalyticsModal(false)}
        >
          <Modal.Header
            style={{ marginLeft: 24, marginTop: 0, marginBottom: 0 }}
          >
            <h4>Klydo Views</h4>
            <i
              className="nc-icon nc-simple-remove"
              style={{ marginTop: 15 }}
              onClick={() => setShowKlydoAnalyticsModal(false)}
            ></i>
          </Modal.Header>

          <MsTable
            id={tableId}
            rowBuild={tableRows}
            search={(row: any, val: string) => {
              return (
                row.klydo?.tags?.find((t: string) => t.toLowerCase() === val) ||
                row.klydo?.idf.includes(val) ||
                row.klydo?.id.includes(val) ||
                row.klydo?.author?.toLowerCase().includes(val) ||
                row.klydo?.name.toLowerCase().includes(val)
              );
            }}
            initSort={"clockTime"}
            data={klydoAnalyticData ? klydoAnalyticData : Array<any>()}
            cols={tableColumn}
            title=""
          />
          {!klydoAnalyticData && (
            <div id="fuse-splash-screen-version2">
              <div className="center">
                <div className="spinner-wrapper">
                  <div className="spinner">
                    <div className="inner">
                      <div className="gap" />
                      <div className="left">
                        <div className="half-circle" />
                      </div>
                      <div className="right">
                        <div className="half-circle" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal>
      )}
    </>
  );
}
const KlydoModalWrapper = (props: any) => {
  const { data: klydo, isLoading } = useKlydo(props.row.id);
  if (isLoading || !klydo) return <Spinner animation="border" />;
  return <KlydoModal {...props} klydo={klydo} />;
};

export default KlydoModalWrapper;
