import { Klydo, Tag, User } from "../../../Types";
import { useCallback, useEffect, useState } from "react";
import { Modal, Button, Row, Form, Spinner } from "react-bootstrap";
import {
  isVideo,
  percentToPresent,
  resizeCloudinary,
} from "../../../utils/util";
import KlydoModal from "./KlydoModal";
import { FaCheckCircle, FaTrash } from "react-icons/fa";
import firebaseService from "../../../firebase_service/firebaseService";
import { calcTimes } from "../../../components/Widgets/TimeCalc";
import ButtonLoader from "../../../components/Widgets/ButtonLoader";
import MsTable from "../../../components/Widgets/Table";
import ScheduledTasksAddModal from "../../ScheduledTaskAddModal";
import { BsTag } from "react-icons/bs";
// eslint-disable-next-line import/no-unresolved
import { debounce } from "lodash";
import { FaCircleXmark } from "react-icons/fa6";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import useKlydosByText from "../../../components/hooks/useKlydosByText";
import id from "../../../friendly-id/id-gen";
const filterOptions = ["Pool", "Not Pooled", "Not Scheduled", "Unlisted"];

const PAGE_SIZE = 20;

function Klydos() {
  const [data, setData] = useState<Array<Klydo>>();
  const [tags, setTags] = useState<Array<Tag>>();
  const [showDelete, setShowDelete] = useState<boolean | Klydo>(false);
  const [showScheduledTaskAddModal, setShowScheduledTaskAddModal] =
    useState(false);
  const [textSearch, setTextSearch] = useState<string>("");
  const [localSelected, setLocalSelected] = useState<Array<Klydo>>([]);
  const [filterValues, setFilterValues] = useState<string[]>([]);

  const handleFreeSearch = useCallback(
    debounce((s) => setTextSearch(s), 500),
    [],
  );

  const {
    data: klydos,
    dataRef: klydosRef,
    fetchNextPage,
    isLoading,
  } = useKlydosByText(textSearch, PAGE_SIZE, {
    pool: filterValues.includes("Pool") ? true : undefined,
    unlisted: filterValues.includes("Unlisted") ? true : undefined,
    notScheduled: filterValues.includes("Not Scheduled") ? true : undefined,
    notPool: filterValues.includes("Not Pooled") ? true : undefined,
  });

  const shouldDisableFilterButton = useCallback(
    (buttonValue: string) => {
      return (
        (buttonValue === "Not Pooled" && filterValues.includes("Pool")) ||
        (buttonValue === "Pool" && filterValues.includes("Not Pooled"))
      );
    },
    [filterValues],
  );

  const tableColumns: Array<{
    sort: boolean;
    field: string;
    label: string;
    size?: number;
    noHeadline?: boolean;
  }> = [
    {
      sort: false,
      field: "tag",
      label: "",
      size: 32,
    },
    {
      sort: true,
      label: "id",
      field: "id",
      size: 200,
    },
    {
      sort: true,
      label: "author",
      field: "author",
      size: 200,
    },
    {
      sort: true,
      label: "name",
      field: "name",
      size: 200,
    },
    {
      sort: false,
      label: "gif",
      field: "image",
    },
    {
      sort: true,
      label: "date",
      field: "createdAt",
      size: 220,
    },
    {
      size: 150,
      sort: true,
      label: "Favorite",
      field: "favs",
    },
    {
      size: 128,
      sort: false,
      label: "Unlisted",
      field: "unlisted",
    },
    {
      size: 128,
      sort: false,
      label: "Public",
      field: "public",
    },
    {
      size: 128,
      sort: false,
      label: "Pool",
      field: "pool",
    },
    {
      size: 128,
      sort: false,
      label: "task",
      field: "task",
    },
    {
      size: 32,
      noHeadline: true,
      sort: false,
      label: "",
      field: "delete",
    },
  ];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const tableRows = (r: Klydo, key: number) => {
    return {
      id: <p>{r.id}</p>,
      image:
        !r.loopUrl || isVideo(r.loopUrl) === "none" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>File type not supported</p>
          </div>
        ) : (
          <div
            style={{
              borderRadius: "50%",
              width: 96,
              height: 96,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                top: r.crop?.top || 0 + "%",
                left: r.crop?.left || 0 + "%",
                width: `${r.crop?.zoom || 100}%`,
                aspectRatio: "1/1",
              }}
            >
              {isVideo(r.loopUrl) === "image" ? (
                <img
                  loading="lazy"
                  alt="gif"
                  src={resizeCloudinary(r.loopUrl, 96)}
                  style={{ width: "auto", height: "100%", objectFit: "cover" }}
                />
              ) : (
                <video
                  muted={true}
                  src={resizeCloudinary(r.loopUrl, 96)}
                  loop
                  autoPlay
                  style={{ width: "auto", height: "100%", objectFit: "cover" }}
                />
              )}
            </div>
          </div>
        ),
      author: <p title={r.creator}>{r.authorName}</p>,
      name: r.name,
      createdAt: r.createdAt?.toLocaleString("en-GB"),
      favs: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {r.favs || 0}
        </div>
      ),
      times: percentToPresent(calcTimes(r.times ?? [])),
      public: <p>{(!!r.public).toString()}</p>,
      unlisted: <p>{(!!r.unlisted).toString()}</p>,
      pool: <p>{(!!r.pool).toString()}</p>,
      task: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {r.inTasks ? (
            <FaCheckCircle color="green" />
          ) : (
            <FaCircleXmark color="red" />
          )}
        </div>
      ),
      key: r.id,
      tag: r.tags?.length ? (
        <BsTag title={"tags:" + r.tags?.reduce((a, b) => a + "\n" + b, "")} />
      ) : (
        ""
      ),
      delete: (
        <FaTrash
          style={{ margin: "8px" }}
          className="nc-icon nc-simple-remove"
          onClick={(e) => {
            e.stopPropagation();
            setShowDelete(r);
          }}
        />
      ),
    };
  };

  function genActions() {
    return (
      <div
        style={{
          width: "23%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          style={{
            marginBottom: "5px",
            marginRight: "5px",
            fontSize: "80%",
            height: "fit-content",
            padding: "6px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => setShowScheduledTaskAddModal(true)}
        >
          Add klydos To Task
        </Button>
        <Button
          style={{
            marginBottom: "5px",
            marginRight: "5px",
            fontSize: "80%",
            height: "fit-content",
            padding: "6px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => setShowDelete(true)}
        >
          Delete klydos
        </Button>
      </div>
    );
  }

  const handleKlydoUpdate = (klydo: Klydo) => {
    if (klydo) {
      setData((data ?? []).map((k: Klydo) => (k.id === klydo?.id ? klydo : k)));
    }
  };

  function genButtons() {
    return (
      <div style={{ marginBottom: "10px", marginTop: "10px" }}>
        <FormControl sx={{ m: 1, minWidth: 130 }}>
          <InputLabel id="filter">Filter</InputLabel>
          <Select
            label="Filter"
            labelId="filter"
            multiple
            value={filterValues}
            onChange={(v) => {
              setFilterValues(
                typeof v.target.value === "string"
                  ? [v.target.value]
                  : v.target.value,
              );
            }}
          >
            {filterOptions.map((v) => (
              <MenuItem
                disabled={shouldDisableFilterButton(v)}
                key={v}
                value={v}
              >
                {v}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }

  return (
    <>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Free Search</Form.Label>
        <Form.Control
          onChange={(e) => {
            handleFreeSearch(e.target.value);
          }}
          type="search"
          placeholder="Happy Klydo"
        />
      </Form.Group>
      {klydos && !isLoading ? (
        <MsTable
          id="klydos"
          cancelSort={!!textSearch}
          buttons={genButtons()}
          actions={() => genActions()}
          onScrollDown={fetchNextPage}
          onAction={(selected: Array<Klydo>) => {
            setLocalSelected(selected);
          }}
          rowBuild={tableRows}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          search={(row: any, val: string) => {
            if (tags?.find((t: Tag) => t.name.toLowerCase() === val))
              return row.tags?.find((t: string) => t.toLowerCase() === val);
            return (
              id.friendlyFromHex(row.id).includes(val) ||
              id.friendlyFromHex(row.id).includes(val) ||
              row.author?.toLowerCase().includes(val) ||
              row.name.toLowerCase().includes(val)
            );
          }}
          data={klydos}
          initSort={"createdAt"}
          cols={tableColumns}
          title="Klydos"
          handleChangedKlydo={handleKlydoUpdate}
          modal={KlydoModal}
          shouldHideSearchBar
        />
      ) : (
        <Spinner></Spinner>
      )}
      <ScheduledTasksAddModal
        open={showScheduledTaskAddModal}
        handleClose={(add: boolean) => {
          setShowScheduledTaskAddModal(add);
        }}
        klydos={localSelected}
      />
      {showDelete && (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <DeleteModal
          done={() => setShowDelete(false)}
          localSelected={showDelete === true ? localSelected : undefined}
          toRemove={showDelete === true ? undefined : showDelete}
        ></DeleteModal>
      )}
    </>
  );
}

export default Klydos;

function DeleteModal(props: {
  toRemove?: Klydo;
  localSelected?: Klydo[];
  done: () => void;
}) {
  const [deleteMsg, setDeleteMsg] = useState("");
  const [warning, setWarning] = useState("");
  return (
    <Modal show={true} onHide={() => props.done()}>
      <Modal.Header style={{ justifyContent: "right" }}>
        <i
          className="nc-icon nc-simple-remove"
          onClick={() => props.done()}
        ></i>
      </Modal.Header>
      <Modal.Body>
        {props.toRemove ? (
          <h4>
            Are you sure you want to remove klydo "{props.toRemove.name}" from
            public?
          </h4>
        ) : (
          <>
            <h4>Are you sure you want to remove these klydos?</h4>
            {props.localSelected!.map((k) => (
              <p key={k.id}>{k.name}</p>
            ))}
          </>
        )}
        <Row className="h-100">
          <Form.Control
            as="textarea"
            rows={4}
            value={deleteMsg}
            onChange={(e) => setDeleteMsg(e.target.value)}
          ></Form.Control>
        </Row>
        {warning && <p style={{ color: "red" }}>{warning}</p>}
      </Modal.Body>
      <Modal.Footer>
        <Button style={{ width: 180, margin: 10 }} onClick={() => props.done()}>
          No
        </Button>
        <ButtonLoader
          style={{ width: 180, margin: 10 }}
          title="Yes"
          onClick={async () => {
            if (!deleteMsg && props.localSelected) {
              setWarning("Must enter a message to delete");
              return;
            }
            if (!deleteMsg) {
              const user = await firebaseService.findItem<User>(
                "users",
                "uid",
                props.toRemove!.creator,
              );
              if (user?.lastRefreshTime) {
                setWarning("Must enter a message to delete");
                return;
              }
            }
            setWarning("");
            if (props.toRemove) {
              try {
                await firebaseService.acceptReview(
                  props.toRemove!,
                  true,
                  deleteMsg,
                );
                props.done();
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } catch (e: any) {
                setWarning(e.message || "an error occured");
              }
            } else {
              try {
                await Promise.all(
                  props.localSelected!.map((k) =>
                    firebaseService.acceptReview(k, true, deleteMsg),
                  ),
                );
                props.done();
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } catch (e: any) {
                setWarning(e.message || "an error occured");
              }
            }
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
