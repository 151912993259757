import { User } from "../Types";
import { baseClient, getUserId } from "./baseRequest";

export type Author = {
  name: string;
  id?: string;
  password?: string;
  photoUrl?: string;
  description?: string;
  country?: string;
  email: string;
  role?: string;
  createdAt: Date;
  state?: string;
  updatedAt?: Date;
  socialMedia?: Array<SocialMedia>;
  tag?: string;
  clock?: string;
  animates?: boolean;
};

export type SocialMedia = {
  link: string;
  network: string;
};

export type AuthorApiClient = {
  getAllAuthors: () => Promise<{ authors: Author[] }>;
  createAuthor: (author: Author) => Promise<{ author: Author }>;
  removeAuthor: (uid: string, role: string) => Promise<void>;
  updateAuthorRole: (uid: string, role: string) => Promise<void>;
  disconnectAuthorClock: () => Promise<void>;
  updateAuthor: (author: Partial<Author>) => Promise<void>;
  getAllUsers: () => Promise<User[]>;
};

const authorApiClient: AuthorApiClient = {
  getAllAuthors: async () => {
    return baseClient.get<{ authors: Author[] }>({
      url: `/authors/get-all`,
    });
  },

  createAuthor: async (author: Author) => {
    return baseClient.post<{ author: Author }>({
      url: `/authors/create-author`,
      body: author,
    });
  },

  removeAuthor: async (uid: string, role: string) => {
    return baseClient.delete<void>({
      url: `/authors/${uid}/remove-author`,
      params: { role },
    });
  },

  updateAuthorRole: async (uid: string, role: string) => {
    return baseClient.put<void>({
      url: `/authors/${uid}/update-author-role`,
      body: { role },
    });
  },

  disconnectAuthorClock: async () => {
    return baseClient.put<void>({
      url: `/authors/${getUserId}/disconnect-author-clock`,
    });
  },

  updateAuthor: async (author: Partial<Author>) => {
    return baseClient.put<void>({
      url: `/authors/${getUserId}/update-author`,
      body: { author },
    });
  },
  getAllUsers: async () => {
    return baseClient.get<User[]>({
      url: `/admin/users`,
    });
  }
};

export default authorApiClient;
