import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  InputGroup,
  ListGroup,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import firebaseService from "../firebase_service/firebaseService";
import { Device, Klydo } from "../Types";
import { ModalActions, isVideo, resizeCloudinary } from "../utils/util";
import { BsCheck } from "react-icons/bs";
import { IoMdAdd } from "react-icons/io";
import LogcatStreamModal from "./LogcatStreamModal";
import DSButton from "./system-design/DSButtom";
import KlydosPickDialog from "./Gifts/GiftCategories/components/KlydosPickDialog";
import DSDialog from "./system-design/DSDialog";
import DSDialogTitle from "./system-design/DSDialogTitle";
import DSDialogContent from "./system-design/DSDialogContent";
import DSDialogActions from "./system-design/DSDialogActions";
import useKlydo from "../components/hooks/useKlydo";

function ScheduleBulkTaskModal({
  open,
  handleClose,
  action,
  selectedDevices,
  userFilter,
}: {
  open: boolean;
  handleClose: (b: boolean) => void;
  action: ModalActions;
  selectedDevices: Array<Device>;
  userFilter: Array<string>;
}) {
  const [schedule, setSchedule] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [scheduleDate, setScheduleDate] = useState<Date>();
  const [errors, setErrors] = useState({
    dateError: "",
    klydoError: "",
    serverError: "",
  });
  const [previewKlydo, setPreviewKlydo] = useState<Klydo>();
  const [params, setParams] = useState<Array<any>>([]);
  const [inputText, setInputText] = useState("");
  const [checkGlobal, setCheckGlobal] = useState(false);
  const [useFilter, setUseFilter] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<Array<Klydo>>();
  const [checkExpire, setCheckExpire] = useState<boolean>(false);
  const [expireDay, setExpireDay] = useState(0);
  const [expireHrs, setExpireHrs] = useState(1);
  const [expireMin, setExpireMin] = useState(0);
  const [showCmdSummary, setShowCmdSummary] = useState(false);
  const [isKlydoModalOpen, setIsKlydoModalOpen] = useState(false);
  const [klydoId, setKlydoId] = useState<string>("");

  const { data: klydo } = useKlydo(klydoId || "");

  useEffect(() => {
    if (klydo) {
      setPreviewKlydo(klydo);
      setValidated(true);
    }
  }, [klydo]);

  const handlePickKlydo = (selectedKlydos: string[]) => {
    setKlydoId(selectedKlydos[0]);
  };

  useEffect(() => {
    if (!open) {
      setParams([]);
      setErrors({ dateError: "", klydoError: "", serverError: "" });
      setPreviewKlydo(undefined);
      setValidated(false);
      setSchedule(false);
      setCheckExpire(false);
      setCheckGlobal(false);
      setLoading(false);
      setInputText("");
      setScheduleDate(undefined);
      setExpireDay(0);
      setExpireMin(0);
      setExpireHrs(1);
      setSuggestions(undefined);
    }
  }, [open]);

  useEffect(() => {
    let klydo: Klydo | undefined = undefined;
    let validated = false;
    let idf = inputText.split("-").length === 3;
    firebaseService
      .findItem<Klydo>("klydos", idf ? "idf" : "id", inputText)
      .then((v) => {
        klydo = v;
        validated = klydo !== undefined;
        setValidated(validated);

        setErrors((errors) =>
          validated || inputText.length === 0
            ? { ...errors, klydoError: "" }
            : { ...errors, klydoError: "There is no such klydo" },
        );
        if (validated) setPreviewKlydo(klydo!);
      });
  }, [inputText]);
  const [isLogcatOpen, setIsLogcatOpen] = useState(false);

  function getHeadline(action: ModalActions) {
    switch (action) {
      case ModalActions.DELETE:
        return "Delete Klydos";
      case ModalActions.ONBOARDING:
        return "Onboard clock";
      case ModalActions.PREVIEW:
        return "Play Klydo";
      case ModalActions.UPDATE:
        return "Update App";
      case ModalActions.SYNC:
        return "Sync Pool Now";
      case ModalActions.SYNC_LOGS:
        return "Sync Logs";
      case ModalActions.UPDATE_LOG_CONFIG:
        return "Update Log Config";
      case ModalActions.LOGCAT:
        return "Logcat";
      default:
        return "";
    }
  }
  //
  function handleLogcat(event: any) {
    event.preventDefault();
    setLoading(true);
    let localParams = ["wss://clock-socket-917257785826.us-central1.run.app"];
    let id = firebaseService.genFbID();
    const deviceId = selectedDevices[0].id;
    firebaseService
      .sendCommandToClocksByBatch({
        id: id,
        name: action,
        local: false,
        devices: [deviceId],
        date: new Date(),
        params: localParams,
      })
      .catch((error) => {
        console.log("Failed to schedule command: " + error);
        setErrors({
          ...errors,
          serverError: "Failed to schedule command: " + error,
        });
        handleClose(true);
      })
      .finally(() => {
        setLoading(false);
      });

    setIsLogcatOpen(true);
  }
  function handleSubmit(event: any) {
    event.preventDefault();
    setLoading(true);
    let expire;
    let localParams = params;
    if (action === ModalActions.PREVIEW || action === ModalActions.DELETE)
      localParams = params.map((klydo) => klydo.id);
    if (checkExpire) expire = expireDay * 24 * 60 + expireHrs * 60 + expireMin;
    const date = new Date();
    let id = firebaseService.genFbID();
    if (
      schedule &&
      (scheduleDate!.getFullYear() !== date.getFullYear() ||
        scheduleDate!.getMonth() !== date.getMonth() ||
        scheduleDate!.getDate() !== date.getDate())
    ) {
      firebaseService
        .scheduleCommandToClocks({
          id: id,
          name: action,
          local: schedule && !checkGlobal,
          devices: useFilter
            ? null
            : selectedDevices.map((device) => device.id),
          filter: useFilter ? userFilter : null,
          expire: expire,
          date: scheduleDate,
          params: localParams,
        })
        .then(() => {
          handleClose(false);
          setShowCmdSummary(false);
        })
        .catch((error) => {
          console.log("Failed to schedule command: " + error);
          setErrors({
            ...errors,
            serverError: "Failed to schedule command: " + error,
          });
          handleClose(true);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      firebaseService
        .sendCommandToClocksByBatch({
          id: id,
          name: action,
          local: schedule && !checkGlobal,
          devices: selectedDevices.map((device) => device.id),
          expire: expire,
          date: scheduleDate ? scheduleDate : new Date(),
          params: localParams,
        })
        .then(() => {
          handleClose(false);
          setShowCmdSummary(false);
        })
        .catch((error) => {
          console.log("Failed to schedule command: " + error);
          setErrors({
            ...errors,
            serverError: "Failed to schedule command: " + error,
          });
          handleClose(true);
        })
        .finally(() => {
          setLoading(false);
        });

      firebaseService
        .scheduleCommandToClocks({
          id: id,
          name: action,
          local: schedule && !checkGlobal,
          devices: selectedDevices.map((device) => device.id),
          expire: expire,
          date: scheduleDate ? scheduleDate : new Date(),
          params: localParams,
        })
        .then(() => {
          handleClose(false);
          setShowCmdSummary(false);
        })
        .catch((error) => {
          console.log("Failed to schedule command: " + error);
          setErrors({
            ...errors,
            serverError: "Failed to schedule command: " + error,
          });
          handleClose(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  const ActionButton = () => {
    return (
      <div style={{ width: "fit-content" }}>
        <Button
          disabled={
            action === ModalActions.DELETE || action === ModalActions.PREVIEW
              ? params.length === 0 ||
                (schedule && (!scheduleDate || scheduleDate! < new Date()))
              : schedule && (!scheduleDate || scheduleDate! < new Date())
          }
          onClick={() => setShowCmdSummary(true)}
          style={{
            color: "#0d6efd",
            padding: 3,
            fontSize: "85%",
            height: "fit-content",
            width: "100%",
          }}
        >
          {schedule ? "Schedule" : getHeadline(action)}
        </Button>
      </div>
    );
  };

  const CapsuleSwitch = (props: {
    defaultOption: string;
    option2: string;
    condition: boolean;
    onSwitch: () => void;
    height: number;
    width: number;
  }) => {
    return (
      <div
        title="Decide if to schedule command or perform it now"
        style={{
          userSelect: "none",
          marginBottom: "40px",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          width: props.width,
          height: props.height,
          background: "#fff",
          borderRadius: "50px / 50px",
          border: "1px solid #0d6efd",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: `${!props.condition ? "#0d6efd" : "#719cdc"}`,
            width: "50%",
            height: "100%",
            borderRadius: "50px 0 0 50px",
            backgroundColor: `${props.condition ? "#ebedf0" : "transparent"}`,
          }}
        >
          {props.option2}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: `${!props.condition ? "#719cdc" : "#0d6efd"}`,
            width: "50%",
            height: "100%",
            borderRadius: "0 50px 50px 0",
            backgroundColor: `${!props.condition ? "#ebedf0" : "transparent"}`,
          }}
        >
          {props.defaultOption}
        </div>
        <div
          style={{
            border: "0.5px solid #0d6efd",
            height: "100%",
            position: "absolute",
          }}
        ></div>
        <div
          className={"btn"}
          style={{
            height: "100%",
            width: "50%",
            position: "absolute",
            backgroundColor: "transparent",
            borderRadius: `${!props.condition ? "50px 0 0 50px" : "0 50px 50px 0"}`,
            left: `${props.condition ? "" : "0"}`,
            right: `${props.condition ? "0" : ""}`,
          }}
          onClick={() => {
            props.onSwitch();
          }}
        ></div>
      </div>
    );
  };

  return (
    <>
      {!showCmdSummary && !isLogcatOpen && (
        <DSDialog fullWidth open={open} onClose={() => handleClose(false)}>
          <DSDialogTitle>
            <i
              style={{ bottom: "7px", position: "relative" }}
              className="nc-icon nc-simple-remove"
              onClick={() => {
                handleClose(false);
              }}
            ></i>
            <h4 style={{ margin: 0, paddingTop: "16px", paddingLeft: "14px" }}>
              {getHeadline(action)}
            </h4>
          </DSDialogTitle>
          <DSDialogContent
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {action !== ModalActions.SYNC && action !== ModalActions.LOGCAT && (
              <CapsuleSwitch
                width={150}
                height={45}
                defaultOption="Now"
                option2="Schedule"
                condition={schedule}
                onSwitch={() => setSchedule(schedule ? false : true)}
              />
            )}

            {action === ModalActions.DELETE && params.length > 0 && (
              <>
                <ListGroup
                  style={{ width: "90%", borderRadius: 0, border: "none" }}
                  className="border-0"
                >
                  <ListGroup.Item style={{ border: 0, height: "90%" }}>
                    <div
                      style={{ fontSize: "12px", color: "gray", border: 0 }}
                      className="d-flex w-100 justify-content-around"
                    >
                      <div style={{ width: 105 }} className="mb-1">
                        IDF
                      </div>
                      <div style={{ width: 105 }} className="mb-1">
                        Author
                      </div>
                      <div
                        style={{ width: 35, left: "7px", position: "relative" }}
                        className="mb-1"
                      >
                        GIF
                      </div>
                      <div
                        style={{
                          width: 12,
                          right: "13px",
                          position: "relative",
                        }}
                        className="mb-1"
                      >
                        Pool
                      </div>
                      <div style={{ width: 6 }} className="mb-1"></div>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
                <ListGroup
                  style={{
                    width: "90%",
                    borderRadius: 0,
                    border: "none",
                    maxHeight: "190px",
                    overflowY: "auto",
                  }}
                  className="border-0"
                >
                  {params.map((klydo) => (
                    <ListGroup.Item
                      key={klydo.id}
                      style={{ border: 0, paddingTop: 0, paddingBottom: 0 }}
                    >
                      <div
                        style={{
                          fontSize: "13px",
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ width: 105 }} title={klydo.id}>
                          {klydo.idf}
                        </div>
                        <div style={{ width: 105 }} title={klydo.creator}>
                          {klydo.author}
                        </div>
                        {!klydo.loopUrl || isVideo(klydo.loopUrl) === "none" ? (
                          <div
                            style={{
                              width: 35,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <p>--</p>
                          </div>
                        ) : (
                          <div
                            style={{
                              borderRadius: "50%",
                              width: 35,
                              height: 35,
                              position: "relative",
                              overflow: "hidden",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                position: "absolute",
                                top: klydo.crop?.top || 0 + "%",
                                left: klydo.crop?.left || 0 + "%",
                                width: `${klydo.crop?.zoom || 100}%`,
                                aspectRatio: "1/1",
                              }}
                            >
                              {isVideo(klydo.loopUrl) === "image" ? (
                                <img
                                  loading="lazy"
                                  alt="gif"
                                  src={resizeCloudinary(klydo.loopUrl, 48)}
                                  style={{
                                    width: "auto",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              ) : (
                                <video
                                  muted={true}
                                  src={resizeCloudinary(klydo.loopUrl, 48)}
                                  loop
                                  autoPlay
                                  style={{
                                    width: "auto",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        )}
                        {klydo.pool ? (
                          <BsCheck
                            style={{ width: 12 }}
                            color="black"
                          ></BsCheck>
                        ) : (
                          <div style={{ width: 12 }}></div>
                        )}
                        <i
                          className="nc-icon nc-simple-remove"
                          style={{ width: 6 }}
                          onClick={() => {
                            let tmp = params.filter(
                              (k) => !(k.id === klydo.id),
                            );
                            setParams([...tmp]);
                          }}
                        ></i>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </>
            )}
            {!loading && (
              <Form
                onKeyDown={(e: React.KeyboardEvent<HTMLFormElement>) => {
                  if (e.key === "Enter") e.preventDefault();
                }}
                style={{ width: "90%", flexDirection: "column" }}
                className="d-flex justify-content-center"
              >
                {action === ModalActions.DELETE && (
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <InputGroup style={{ width: "53%" }}>
                      <Form.Control
                        style={{
                          zIndex: 0,
                          borderRadius: "0.375rem",
                          borderLeft: "outset",
                          borderRight: "inset",
                          border: "1px solid #969696",
                        }}
                        isValid={validated}
                        placeholder="Enter klydo"
                        required
                        value={inputText}
                        onChange={(e) => {
                          let inText = e.target.value;
                          let idf = inText.split("-").length === 3;
                          let klydo: Klydo | undefined = undefined;
                          let validated = false;
                          firebaseService
                            .findItem<Klydo>(
                              "klydos",
                              idf ? "idf" : "id",
                              inText,
                            )
                            .then((v) => {
                              klydo = v;
                              validated = klydo !== undefined;
                              setValidated(validated);
                              if (validated) {
                                setPreviewKlydo(klydo!);
                              }
                            });
                          setErrors(
                            validated || inText.length === 0
                              ? { ...errors, klydoError: "" }
                              : {
                                  ...errors,
                                  klydoError: "There is no such klydo",
                                },
                          );
                          if (validated) {
                            setSuggestions(undefined);
                            setErrors(
                              params.includes(previewKlydo!)
                                ? {
                                    ...errors,
                                    klydoError: "klydo already listed",
                                  }
                                : { ...errors, klydoError: "" },
                            ); //still does not work
                          } else
                            firebaseService
                              .getData<Klydo>("klydos")
                              .then((v) =>
                                setSuggestions(
                                  v.filter(
                                    (klydo: Klydo) =>
                                      !params.includes(klydo) &&
                                      (klydo.id.includes(inputText) ||
                                        klydo.idf.includes(inputText)),
                                  ),
                                ),
                              );

                          setInputText(inText);
                        }}
                        type="text"
                      ></Form.Control>

                      {suggestions && (
                        <ul id="suggestionList" className="suggestion-list">
                          {suggestions.map((suggestion) => (
                            <li
                              className="btn"
                              style={{
                                borderRight: 0,
                                borderRadius: 0,
                                fontSize: "12px",
                                paddingRight: 0,
                                paddingLeft: 0,
                                color: "#423e3e",
                                borderBottom: 0,
                                borderTop: 0,
                                zIndex: 999,
                                border: "1px solid #eee",
                                width: "100%",
                              }}
                              onClick={() => {
                                setInputText(suggestion.idf);
                                setSuggestions(undefined);
                              }}
                            >
                              {suggestion.idf}
                            </li>
                          ))}
                        </ul>
                      )}
                    </InputGroup>
                    {errors.klydoError && (
                      <p
                        style={{
                          color: "red",
                          marginBottom: 0,
                          marginLeft: "10px",
                        }}
                      >
                        {errors.klydoError}
                      </p>
                    )}
                    {validated && !errors.klydoError && (
                      <Button
                        variant="outline-dark"
                        style={{
                          color: "black",
                          height: "fit-content",
                          marginTop: "10px",
                          marginLeft: "5%",
                          marginBottom: "10px",
                          fontSize: "87%",
                        }}
                        onClick={() => {
                          if (!params.includes(previewKlydo!)) {
                            params.push(previewKlydo!);
                            setParams((prev) => prev.slice());
                            setInputText("");
                            setSuggestions(undefined);
                          } else
                            setErrors({
                              ...errors,
                              klydoError: "klydo already listed",
                            });
                        }}
                      >
                        <IoMdAdd />
                      </Button>
                    )}
                  </div>
                )}
                {action === ModalActions.PREVIEW && (
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <DSButton
                      type="button"
                      sx={{ color: "black", backgroundColor: "#f0f0f0" }}
                      onClick={() => {
                        setIsKlydoModalOpen(true);
                      }}
                    >
                      Select Klydos
                    </DSButton>
                    {isKlydoModalOpen && (
                      <KlydosPickDialog
                        selectedKlydos={klydoId ? [klydoId] : []}
                        onPickKlydos={handlePickKlydo}
                        onClose={() => setIsKlydoModalOpen(false)}
                        limit={1}
                      />
                    )}
                  </div>
                )}

                {!loading && schedule && (
                  <Form.Group
                    controlId="formAddDate"
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "80%",
                    }}
                  >
                    <Col style={{ width: "53%", zIndex: 5 }}>
                      <DatePicker
                        selected={
                          scheduleDate
                            ? new Date(
                                scheduleDate!.getTime() +
                                  scheduleDate!.getTimezoneOffset() * 60 * 1000,
                              )
                            : undefined
                        }
                        onChange={(chosenDate) => {
                          try {
                            let dateStr = chosenDate!.toString();
                            setScheduleDate(
                              new Date(
                                dateStr.substring(0, dateStr.indexOf("GMT")) +
                                  "GMT",
                              ),
                            );
                            const dt = new Date(chosenDate!);
                            setErrors(
                              dt > new Date()
                                ? { ...errors, dateError: "" }
                                : {
                                    ...errors,
                                    dateError: "Date must be in the future",
                                  },
                            );
                          } catch (e) {}
                        }}
                        showTimeSelect
                        timeIntervals={15}
                        showIcon
                        placeholderText="Choose date"
                        timeFormat="HH:mm"
                        dateFormat="yyyy-MM-dd HH:mm"
                        className={`form-control  ${errors.dateError ? "is-invalid" : ""}`}
                        required
                      />

                      {errors.dateError && (
                        <p style={{ color: "red" }}>{errors.dateError}</p>
                      )}
                    </Col>
                    <label
                      style={{
                        marginTop: "7px",
                        marginRight: "10px",
                        marginLeft: "10px",
                      }}
                    >
                      <input
                        style={{ marginRight: "5px" }}
                        type="radio"
                        title="Local"
                        checked={!checkGlobal}
                        onChange={() => setCheckGlobal(!checkGlobal)}
                      />
                      Local
                    </label>
                    <label style={{ marginTop: "7px" }}>
                      <input
                        style={{ marginRight: "5px" }}
                        type="radio"
                        title="GMT"
                        checked={checkGlobal}
                        onChange={() => setCheckGlobal(!checkGlobal)}
                      />
                      GMT
                    </label>
                  </Form.Group>
                )}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {action === ModalActions.SYNC && (
                    <label
                      style={{
                        width: "fit-content",
                        display: "flex",
                        alignItems: "center",
                        lineHeight: 0,
                      }}
                    >
                      <Form.Switch
                        style={{ lineHeight: "1.5em" }}
                        defaultChecked={false}
                        type="switch"
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                          if (e.target.checked) params.push("packs");
                          else params.splice(0, 1);
                          setParams((prev) => prev.slice());
                          console.log(params);
                        }}
                      />
                      Only Packs
                    </label>
                  )}
                  {action !== ModalActions.LOGCAT && (
                    <label
                      title="Job expires if the clock does not receive it within the specified time frame"
                      style={{ width: "fit-content", marginTop: "7px" }}
                    >
                      <input
                        style={{ marginRight: "5px" }}
                        type="checkbox"
                        checked={checkExpire!}
                        onChange={() => setCheckExpire(!checkExpire)}
                      />
                      Expiration
                    </label>
                  )}
                  {checkExpire && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        width: "35%",
                        justifyContent: "space-between",
                      }}
                    >
                      <label
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "32%",
                          fontSize: "x-small",
                        }}
                      >
                        <input
                          style={{ fontSize: "small", height: "100%" }}
                          value={expireDay}
                          type="number"
                          title="Days"
                          placeholder="day"
                          onChange={(e) =>
                            setExpireDay(parseInt(e.target.value))
                          }
                        />
                        Days
                      </label>
                      <label
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "32%",
                          fontSize: "x-small",
                        }}
                      >
                        <input
                          style={{ fontSize: "small", height: "100%" }}
                          value={expireHrs}
                          type="number"
                          title="Hours"
                          defaultValue={expireHrs}
                          placeholder="hrs"
                          onChange={(e) =>
                            setExpireHrs(parseInt(e.target.value))
                          }
                        />
                        Hours
                      </label>
                      <label
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "32%",
                          fontSize: "x-small",
                        }}
                      >
                        <input
                          style={{ fontSize: "small", height: "100%" }}
                          value={expireMin}
                          type="number"
                          title="Minutes"
                          placeholder="min"
                          onChange={(e) => {
                            setExpireMin(parseInt(e.target.value));
                          }}
                        />
                        Minutes
                      </label>
                    </div>
                  )}
                </div>
                {action === ModalActions.LOGCAT && (
                  <div>
                    <p>Are you sure about the action?</p>
                  </div>
                )}
                {userFilter.length > 0 && schedule && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      margin: "7px",
                      marginLeft: 0,
                    }}
                  >
                    <label style={{ marginRight: "10px" }}>
                      <input
                        style={{ marginRight: "3px" }}
                        type="radio"
                        title="Use filter"
                        checked={useFilter}
                        onChange={() => setUseFilter(useFilter ? false : true)}
                      />
                      Use search filter
                    </label>
                    <label style={{}}>
                      <input
                        style={{ marginRight: "3px" }}
                        type="radio"
                        title="Use selected"
                        checked={!useFilter}
                        onChange={() => setUseFilter(useFilter ? false : true)}
                      />
                      Use {selectedDevices.length} selected devices
                    </label>
                  </div>
                )}
                {useFilter && schedule ? (
                  <div
                    style={{
                      overflowY: "auto",
                      maxHeight: "80px",
                      width: "53%",
                    }}
                  >
                    {userFilter.map((f, index) => (
                      <p
                        style={{ fontSize: "12px", margin: "3px" }}
                        key={index}
                      >
                        {f}
                      </p>
                    ))}
                  </div>
                ) : (
                  ""
                )}
              </Form>
            )}
          </DSDialogContent>

          <DSDialogActions
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <div style={{ width: "fit-content" }}>
              <Button
                onClick={() => handleClose(false)}
                variant="outline-dark"
                style={{
                  padding: 3,
                  color: "#000000",
                  fontSize: "85%",
                  height: "fit-content",
                  width: "100%",
                }}
              >
                Cancel
              </Button>
            </div>
            {(action === ModalActions.DELETE ||
              action === ModalActions.UPDATE ||
              action === ModalActions.ONBOARDING) && <ActionButton />}
            {action === ModalActions.PREVIEW && (
              <Button
                disabled={
                  !validated ||
                  !previewKlydo ||
                  (schedule && !scheduleDate) ||
                  scheduleDate! < new Date()
                }
                onClick={() => {
                  if (!params.includes(previewKlydo!)) {
                    params.push(previewKlydo!);
                    setParams((prev) => prev.slice());
                    setInputText("");
                    setSuggestions(undefined);
                  } else
                    setErrors({
                      ...errors,
                      klydoError: "klydo already listed",
                    });
                  setShowCmdSummary(true);
                }}
                style={{
                  color: "#0d6efd",
                  padding: 3,
                  fontSize: "85%",
                  height: "fit-content",
                  width: "fit-content",
                }}
              >
                {schedule ? "Schedule" : "Play"}
              </Button>
            )}
            {action === ModalActions.SYNC && (
              <Button
                onClick={() => setShowCmdSummary(true)}
                style={{
                  color: "#0d6efd",
                  padding: 3,
                  fontSize: "85%",
                  height: "fit-content",
                  width: "fit-content",
                }}
              >
                Sync
              </Button>
            )}
            {action === ModalActions.SYNC_LOGS && (
              <Button
                onClick={() => setShowCmdSummary(true)}
                style={{
                  color: "#0d6efd",
                  padding: 3,
                  fontSize: "85%",
                  height: "fit-content",
                  width: "fit-content",
                }}
              >
                Sync Logs
              </Button>
            )}
            {action === ModalActions.UPDATE_LOG_CONFIG && (
              <Button
                onClick={() => setShowCmdSummary(true)}
                style={{
                  color: "#0d6efd",
                  padding: 3,
                  fontSize: "85%",
                  height: "fit-content",
                  width: "fit-content",
                }}
              >
                Update Log Config
              </Button>
            )}
            {action === ModalActions.LOGCAT && (
              <Button
                onClick={(event) => {
                  handleLogcat(event);
                }}
                style={{
                  color: "#0d6efd",
                  padding: 3,
                  fontSize: "85%",
                  height: "fit-content",
                  width: "fit-content",
                }}
              >
                Open Logcat
              </Button>
            )}
            {errors.serverError && <p>{errors.serverError}</p>}
          </DSDialogActions>
        </DSDialog>
      )}
      {isLogcatOpen && (
        <LogcatStreamModal
          deviceId={selectedDevices[0].id}
          onClose={() => {
            setIsLogcatOpen(false);
            handleClose(false);
          }}
        />
      )}
      {showCmdSummary && (
        <Modal
          style={{}}
          show={showCmdSummary}
          onHide={() => setShowCmdSummary(false)}
          className="modal-medium"
        >
          <Modal.Body>
            <Row style={{ margin: 25 }}>
              <h4 style={{ textAlign: "center" }}>
                You are about to {getHeadline(action).split(" ")[0]}
                {action === ModalActions.DELETE
                  ? " " + params.length + " klydos "
                  : action === ModalActions.PREVIEW
                    ? " klydo "
                    : action === ModalActions.UPDATE
                      ? " App "
                      : action === ModalActions.SYNC
                        ? " Pool "
                        : action === ModalActions.SYNC_LOGS
                          ? " Logs "
                          : action === ModalActions.ONBOARDING
                            ? " Clock "
                            : " Log Config "}
                on {selectedDevices.length} devices.
              </h4>
              {schedule && (
                <p style={{ textAlign: "center" }}>
                  Scheduled for:
                  <br /> {scheduleDate!.toUTCString().replace("GMT", "")}{" "}
                  {checkGlobal ? "GMT" : "CLT (Clock Local Time)"}
                </p>
              )}
              {checkExpire && (
                <p style={{ textAlign: "center" }}>
                  Command will expire for devices who wont receive it within the
                  selected time frame: <br /> {expireDay}d:{expireHrs}h:
                  {expireMin}m
                </p>
              )}
              {useFilter && (
                <p style={{ textAlign: "center" }}>
                  Command will be performed on more or less devices, based on
                  your search filter
                </p>
              )}
            </Row>
            {(!schedule ||
              (() => {
                let previousDaySched = new Date(scheduleDate!.getTime());
                previousDaySched.setDate(previousDaySched.getDate() - 1);
                previousDaySched.setHours(23, 45, 0, 0);
                const currentDateTime = new Date();
                return currentDateTime.getTime() > previousDaySched.getTime();
              })()) && (
              <Alert
                style={{ textAlign: "center" }}
                show={!!alert}
                variant="danger"
              >
                <h5 style={{ margin: 2 }}>This action cannot be undone</h5>
              </Alert>
            )}
            <Row className="justify-content-between">
              <Col sm="5">
                <Button
                  variant="outline-dark"
                  className="w-100 h-100"
                  onClick={() => setShowCmdSummary(false)}
                >
                  Cancel
                </Button>
              </Col>
              <Col sm="5">
                <Button
                  variant="outline-dark"
                  title="Submit cannot be undone"
                  className={`w-100 h-100 form-control ${!loading && "is-invalid"}`}
                  onClick={handleSubmit}
                >
                  {loading && <Spinner animation="border" size="sm" />}Submit
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default ScheduleBulkTaskModal;
